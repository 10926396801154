import * as types from "../types/screen_type";

const initialState = {
  screenWidth: typeof window == "object" ? window.innerWidth : null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESIZE_WINDOW: {
      return {
        ...state,
        screenWidth: action.screenWidth,
      };
    }

    default: {
      return state;
    }
  }
};
