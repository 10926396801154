import * as types from "../types/company_type";

const initialState = {
  // For company list
  fetchingCompanyList: false,
  companies: [],
  companyCurrentPage: 1,
  companyListPageInfo: {},
  companyFilter: {},
  companyEndCursor: "",
  companyReachedEnd: false,
  companyError: false,
  companyErrorMessage: "",
  isLoadingCompanies: false,
  currentLoadingCompanyKey: "",
  // For company static keyword search
  staticKeyword: "",
  // For company suggestion on mobile filter
  companySuggestions: [],
  suggestionPage: 1,
  fetchingCompanySuggestion: false,
  currentSuggestionKey: "",
  companySuggestionEnd: false,
  // For discover more company
  fetchingDiscoverMoreCompanyList: false,
  discoverMoreCompanies: [],
  discoverMoreCompanyPageInfo: {},
  discoverMoreCompanyCurrentPage: 1,
  discoverCompanyEndCursor: "",
  discoverMoreCompanyReachedEnd: false,
  discoverMoreCompanyError: false,
  // For fetching bookmarked job list
  bookmarkedCompanies: [],
  fetchingBookmarkCompanyList: false,
  bookmarkCompanyListPageInfo: {},
  fetchingBookmarkCompanyListError: false,
  // For fetching industry
  fetchingIndustry: false,
  fetchingIndustryError: false,
  industries: [],
  // For fetching state region
  fetchingStateRegion: false,
  fetchingStateRegionError: false,
  stateRegions: [],
  // For fetching company size
  fetchingCompanySize: false,
  fetchingCompanySizeError: false,
  companySizes: [],
  // For company profile
  selectedCompany: {},
  fetchingCompany: false,
  fetchingCompanyError: false,
  fetchingCompanyErrorMessage: "",
  // for company affiliates
  companyAffiliates: [],
  fetchingCompanyAffiliates: false,
  fetchingCompanyAffiliatesError: false,
  // For company affiliates bookmark
  bookmarkingCompanyAffiliates: false,
  bookmarkingCompanyAffiliatesError: false,
  unbookmarkingCompanyAffiliates: false,
  unbookmarkCompanyAffiliatesError: false,

  // For bookmark company
  bookmarkingCompany: false,
  bookmarkingCompanyError: false,
  // For unbookmark company
  unbookmarkCompany: false,
  unbookmarkCompanyError: false,

  // For company job
  companyJobs: [],
  fetchingCompanyJob: false,
  fetchingCompanyJobError: false,

  // For bookmark company job
  bookmarkingCompanyJob: false,
  bookmarkingCompanyJobError: false,
  // For unbookmark company job
  unbookmarkingCompanyJob: false,
  unbookmarkingCompanyJobError: false,

  // For cp discover more companies
  cpDiscoverMoreCompanies: [],
  fetchingCPDiscoverMoreCompany: false,
  fetchingCPDiscoverMoreCompanyError: false,
  // For cp discover more companies bookmark
  bookmarkingDiscoverCompany: false,
  unbookmarkDiscoverCompany: false,
  bookmarkingDiscoverCompanyError: false,
  // For discover companies
  discoverCompanies: [],
  fetchingDiscoverCompanies: false,
  // For static companies
  staticCompanies: [],
  fetchingStaticCompanies: false,
  // For homepage hiring companies
  homepageHiringCompanies: [],
  fetchingHomepageHiringCompanies: false,

  companyPageBanner: {},

  // For Pre Sign-up Employer Landing page
  employerTrustedLogos: [],
  employerTestimonials: [],

  externalJobBoard: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // For company list
    case types.FETCHING_COMPANIES: {
      return {
        ...state,
        fetchingCompanyList: true,
      };
    }
    case types.FETCH_COMPANIES_SUCCEED: {
      const companyList = action.payload.companyList;
      let cCompanyList = action.payload.refresh ? [] : [...state.companies];
      let companyEnd = state.companyReachedEnd;
      let companyEndCursor = state.companyEndCursor;
      let cFetchingCompanyList = state.fetchingCompanyList;

      // Preventing deprecated graphQL response from overriding main response

      if (
        action.payload.currentLoadingCompanyKey ==
        state.currentLoadingCompanyKey
      ) {
        companyEnd = !action.payload.hasNextPage;
        companyEndCursor = action.payload.companyEndCursor;
        cFetchingCompanyList = false;
        cCompanyList = [...cCompanyList, ...companyList];
      }

      return {
        ...state,
        companies: cCompanyList,
        companyReachedEnd: companyEnd,
        companyEndCursor: companyEndCursor,
        fetchingCompanyList: cFetchingCompanyList,
        staticKeyword: action.payload.keyword,
        companyError: false,
      };
    }
    case types.FETCH_COMPANIES_FAILED: {
      let cFetchingCompanyList =
        action.payload.currentLoadingCompanyKey ==
        state.currentLoadingCompanyKey
          ? false
          : state.fetchingCompanyList;

      return {
        ...state,
        fetchingCompanyList: cFetchingCompanyList,
        companyError: true,
      };
    }

    // For microsite company list
    case types.FETCH_MICROSITE_COMPANIES_SUCCEED: {
      let cCompanyList = state.companies;
      const companyList = action?.payload?.companyList;

      cCompanyList.length > 0 &&
        (cCompanyList = [...cCompanyList, ...companyList]);

      return {
        ...state,
        companies: cCompanyList.length > 0 ? cCompanyList : companyList,
        fetchingCompanyList: false,
        companyError: false,
        discoverMoreCompanyPageInfo: action.payload.pageInfo,
      };
    }
    case types.FETCH_MICROSITE_COMPANIES_FAILED: {
      return {
        ...state,
        companyError: true,
      };
    }
    case types.CLEAR_COMPANY_LIST: {
      return {
        ...state,
        companies: [],
        companyCurrentPage: 1,
        companyListPageInfo: {},
        companyReachedEnd: false,
      };
    }

    // For company suggestion
    case types.FETCH_COMPANY_SUGGESTION: {
      return {
        ...state,
        fetchingCompanySuggestion: true,
      };
    }
    case types.FETCH_COMPANY_SUGGESTION_SUCCEED: {
      let companySuggestionList = action.payload.refresh
        ? []
        : [...state.companySuggestions];
      let cPage = state.suggestionPage;
      let cFetchingCompanySuggestion = state.fetchingCompanySuggestion;
      let companySuggestionEnd = state.companySuggestionEnd;

      // Only update suggestion company list if currentSuggestionKey is the same as current state value
      if (action.payload.currentSuggestionKey == state.currentSuggestionKey) {
        let cCompanySuggestions = action.payload.companySuggestions.map(
          (item) => item.company
        );
        companySuggestionList = [
          ...companySuggestionList,
          ...cCompanySuggestions,
        ];
        cPage = action.payload.suggestionPage + 1;
        cFetchingCompanySuggestion = false;
        companySuggestionEnd =
          action.payload.companySuggestions.length >= 21 ? false : true;
      }

      return {
        ...state,
        fetchingCompanySuggestion: cFetchingCompanySuggestion,
        companySuggestions: companySuggestionList,
        suggestionPage: cPage,
        companySuggestionEnd: companySuggestionEnd,
      };
    }
    case types.FETCH_COMPANY_SUGGESTION_FAILED: {
      // Use back existing fetchingCompanySuggestion status if key does not match
      let cFetchingCompanySuggestion =
        action.payload.currentSuggestionKey == state.currentSuggestionKey
          ? false
          : state.fetchingCompanySuggestion;

      return {
        ...state,
        fetchingCompanySuggestion: cFetchingCompanySuggestion,
      };
    }
    case types.CLEAR_COMPANY_SUGGESTION: {
      return {
        ...state,
        suggestionPage: 1,
        companySuggestions: [],
      };
    }

    // For company filter
    case types.UPDATE_COMPANY_FILTER: {
      return {
        ...state,
        companyFilter: action.companyFilter,
      };
    }

    // For discover more company
    case types.FETCH_DISCOVER_MORE_COMPANIES: {
      return {
        ...state,
        fetchingDiscoverMoreCompanyList: true,
      };
    }

    case types.FETCH_DISCOVER_MORE_COMPANIES_SUCCEED: {
      let cCompanyList = state.discoverMoreCompanies;
      const companyList = action?.payload?.companyList;
      let companyEnd = action?.payload?.hasNextPage;
      let companyEndCursor = action?.payload?.companyEndCursor;

      cCompanyList?.length > 0 &&
        (cCompanyList = [...cCompanyList, ...companyList]);

      return {
        ...state,
        discoverMoreCompanies:
          cCompanyList?.length > 0 ? cCompanyList : companyList,
        discoverCompanyReachedEnd: companyEnd,
        discoverCompanyEndCursor: companyEndCursor,
        fetchingDiscoverMoreCompanyList: false,
        discoverMoreCompanyError: false,
      };
    }

    case types.FETCH_DISCOVER_MORE_COMPANIES_FAILED: {
      return {
        ...state,
        fetchingDiscoverMoreCompanyList: false,
        discoverMoreCompanyError: true,
      };
    }

    case types.CLEAR_DISCOVER_MORE_COMPANY_LIST: {
      return {
        ...state,
        discoverMoreCompanies: [],
        discoverMoreCompanyCurrentPage: 1,
        discoverMoreCompanyPageInfo: {},
        discoverMoreCompanyReachedEnd: false,
      };
    }
    case types.NO_DISCOVER_MORE_COMPANIES_FOUND: {
      return {
        ...state,
        fetchingDiscoverMoreCompanyList: false,
        discoverMoreCompanyError: true,
      };
    }

    // For fetching industry
    case types.FETCHING_INDUSTRY_SUCCEED: {
      let initialIndustries = [];
      return {
        ...state,
        industries: [...initialIndustries, ...action.industries],
        fetchingIndustry: false,
        fetchingIndustryError: false,
      };
    }
    case types.FETCHING_INDUSTRY_FAILED: {
      return {
        ...state,
        fetchingIndustry: false,
        fetchingIndustryError: true,
      };
    }

    // For state region
    case types.FETCHING_STATE_REGION_SUCCEED: {
      return {
        ...state,
        stateRegions: action.payload,
        fetchingStateRegion: false,
        fetchingStateRegionError: false,
      };
    }
    case types.FETCHING_STATE_REGION_FAILED: {
      return {
        ...state,
        fetchingStateRegion: false,
        fetchingStateRegionError: true,
      };
    }
    case types.UPDATE_STATE_REGION: {
      let initialStateRegions = [];
      return {
        ...state,
        stateRegions: [...initialStateRegions, ...action.stateRegions],
      };
    }

    // For company sizes
    case types.FETCHING_COMPANY_SIZE_SUCCEED: {
      let initialCompanySizes = [];
      return {
        ...state,
        companySizes: [...initialCompanySizes, ...action.companySizes],
        fetchingCompanySize: false,
        fetchingCompanySizeError: false,
      };
    }
    case types.FETCHING_COMPANY_SIZE_FAILED: {
      return {
        ...state,
        fetchingCompanySize: false,
        fetchingCompanySizeError: true,
      };
    }

    // For selected company
    case types.UPDATE_SELECTED_COMPANY: {
      return {
        ...state,
        selectedCompany: action.selectedCompany,
      };
    }

    // For company profile
    case types.UPDATE_SELECTED_COMPANY: {
      return {
        ...state,
        selectedCompany: action.selectedCompany,
      };
    }
    case types.FETCHING_COMPANY: {
      return {
        ...state,
        fetchingCompany: true,
      };
    }
    case types.FETCHING_COMPANY_SUCCEED: {
      return {
        ...state,
        selectedCompany: action.payload.selectedCompany,
        fetchingCompany: false,
      };
    }
    case types.FETCHING_COMPANY_FAILED: {
      return {
        ...state,
        fetchingCompany: false,
        fetchingCompanyError: true,
      };
    }

    // For company bookmark
    case types.FETCHING_COMPANY_BOOKMARK: {
      return {
        ...state,
        fetchingCompany: true,
      };
    }
    case types.FETCHING_COMPANY_BOOKMARK_SUCCEED: {
      return {
        ...state,
        selectedCompany: action.payload.selectedCompany,
        fetchingCompany: false,
      };
    }
    case types.FETCHING_COMPANY_BOOKMARK_FAILED: {
      return {
        ...state,
        fetchingCompany: false,
        fetchingCompanyError: true,
      };
    }

    // For bookmarked company list
    case types.FETCH_COMPANY_BOOKMARK_LIST: {
      return {
        ...state,
        fetchingBookmarkCompanyList: true,
      };
    }
    case types.FETCH_COMPANY_BOOKMARK_LIST_SUCCEED: {
      const bookmarkList = action.payload.nodes;
      let cBookmarkCompanies = [...state.bookmarkedCompanies, ...bookmarkList];

      return {
        ...state,
        bookmarkedCompanies: cBookmarkCompanies,
        bookmarkCompanyListPageInfo: action.payload.pageInfo,
        fetchingBookmarkCompanyList: false,
        fetchingBookmarkCompanyListError: false,
      };
    }
    case types.FETCH_COMPANY_BOOKMARK_LIST_FAILED: {
      return {
        ...state,
        fetchingBookmarkCompanyList: false,
        fetchingBookmarkCompanyListError: true,
      };
    }
    case types.CLEAR_COMPANY_BOOKMARK_LIST: {
      return {
        ...state,
        bookmarkedCompanies: [],
      };
    }

    // For bookmark company
    case types.BOOKMARK_COMPANY: {
      return {
        ...state,
        bookmarkingCompany: true,
      };
    }
    case types.BOOKMARK_COMPANY_SUCCEED: {
      return {
        ...state,
        bookmarkingCompany: false,
        bookmarkingCompanyError: false,
      };
    }
    case types.BOOKMARK_COMPANY_FAILED: {
      return {
        ...state,
        bookmarkingCompany: false,
        bookmarkingCompanyError: true,
      };
    }

    // For bookmark discover company
    case types.BOOKMARK_DISCOVER_COMPANY: {
      return {
        ...state,
        bookmarkingDiscoverCompany: true,
      };
    }
    case types.BOOKMARK_DISCOVER_COMPANY_SUCCEED: {
      let companyBookmark = action?.payload;
      let cCompanyList = state.cpDiscoverMoreCompanies;

      const companyList = cCompanyList.map((company) => {
        if (company.company.id === companyBookmark.id) {
          return {
            company: { ...company.company, bookmark: companyBookmark.bookmark },
          };
        }
        return company;
      });
      return {
        ...state,
        cpDiscoverMoreCompanies: companyList,
        bookmarkingDiscoverCompany: false,
        bookmarkingDiscoverCompanyError: false,
      };
    }

    // For bookmark affiliate company
    case types.BOOKMARK_AFFILIATE_COMPANY: {
      return {
        ...state,
        bookmarkingCompanyAffiliates: true,
      };
    }

    case types.BOOKMARK_AFFILIATE_COMPANY_SUCCEED: {
      let companyBookmark = action?.payload;
      let cCompanyAffiliates = state.companyAffiliates?.company;

      if (cCompanyAffiliates.affiliatedCompany === null) {
        cCompanyAffiliates.companyAffiliation.forEach((company) => {
          if (company.affiliatedCompany.id === companyBookmark.id) {
            company.affiliatedCompany.bookmark = companyBookmark.bookmark;
          }
        });
      } else {
        cCompanyAffiliates.affiliatedCompany.company.bookmark =
          companyBookmark.bookmark;
      }

      return {
        ...state,
        companyAffiliates: { company: cCompanyAffiliates },
        bookmarkingCompanyAffiliates: false,
        bookmarkingCompanyAffiliatesError: false,
      };
    }

    case types.BOOKMARK_COMPANY_JOB: {
      return {
        ...state,
        bookmarkingCompanyJob: true,
      };
    }
    // For bookmark company job
    case types.BOOKMARK_COMPANY_JOB_SUCCEED: {
      let jobBookmark = action?.payload;
      let cCompanyJob = state.companyJobs;

      const indexToUpdate = cCompanyJob.findIndex(
        (job) => job.id === jobBookmark.id
      );

      if (indexToUpdate !== -1) {
        cCompanyJob[indexToUpdate].bookmark = jobBookmark.bookmark;
      }
      return {
        ...state,
        companyJobs: cCompanyJob,
        bookmarkingCompanyJob: false,
        bookmarkingCompanyJobError: false,
      };
    }
    case types.BOOKMARK_COMPANY_JOB_FAILED: {
      return {
        ...state,
        bookmarkingCompanyJob: false,
        bookmarkingCompanyJobError: true,
      };
    }

    // For unbookmark company
    case types.UNBOOKMARK_COMPANY: {
      return {
        ...state,
        unbookmarkCompany: true,
      };
    }
    case types.UNBOOKMARK_COMPANY_SUCCEED: {
      let companyId = action.selectedCompany.id;

      let bookmarkCompanies = state.bookmarkedCompanies.filter((bookmark) => {
        if ((bookmark.company && bookmark.company.id) == companyId) {
          return false;
        }
        return true;
      });

      return {
        ...state,
        bookmarkedCompanies: bookmarkCompanies,
        unbookmarkCompany: false,
        unbookmarkCompanyError: false,
      };
    }
    case types.UNBOOKMARK_COMPANY_FAILED: {
      return {
        ...state,
        unbookmarkCompany: false,
        unbookmarkCompanyError: true,
      };
    }

    // For unbookmark discover company
    case types.UNBOOKMARK_DISCOVER_COMPANY: {
      return {
        ...state,
        unbookmarkDiscoverCompany: true,
      };
    }
    case types.UNBOOKMARK_DISCOVER_COMPANY_SUCCEED: {
      let companyBookmark = action?.payload;
      let cCompanyList = state.cpDiscoverMoreCompanies;
      const companyList = cCompanyList.map((company) => {
        if (company.company.id === companyBookmark.id) {
          return {
            company: { ...company.company, bookmark: companyBookmark.bookmark },
          };
        }
        return company;
      });

      return {
        ...state,
        cpDiscoverMoreCompanies: companyList,
        unbookmarkDiscoverCompany: false,
        bookmarkingDiscoverCompanyError: false,
      };
    }

    // For unbookmark affiliate company
    case types.UNBOOKMARK_AFFILIATE_COMPANY: {
      return {
        ...state,
        unbookmarkCompanyAffiliates: true,
      };
    }

    case types.UNBOOKMARK_AFFILIATE_COMPANY_SUCCEED: {
      let companyBookmark = action?.payload;
      let cCompanyAffiliates = state.companyAffiliates?.company;

      if (cCompanyAffiliates.affiliatedCompany === null) {
        cCompanyAffiliates.companyAffiliation.forEach((company) => {
          if (company.affiliatedCompany.id === companyBookmark.id) {
            company.affiliatedCompany.bookmark = companyBookmark.bookmark;
          }
        });
      } else {
        cCompanyAffiliates.affiliatedCompany.company.bookmark =
          companyBookmark.bookmark;
      }

      return {
        ...state,
        companyAffiliates: { company: cCompanyAffiliates },
        unbookmarkCompanyAffiliates: false,
        unbookmarkingCompanyAffiliatesError: false,
      };
    }

    // For unbookmark company job
    case types.UNBOOKMARK_COMPANY_JOB: {
      return {
        ...state,
        unbookmarkingCompanyJob: true,
      };
    }

    case types.UNBOOKMARK_COMPANY_JOB_SUCCEED: {
      let jobBookmark = action?.payload;
      let cCompanyJob = state.companyJobs;

      const indexToUpdate = cCompanyJob.findIndex(
        (job) => job.id === jobBookmark.id
      );

      if (indexToUpdate !== -1) {
        cCompanyJob[indexToUpdate].bookmark = jobBookmark.bookmark;
      }

      return {
        ...state,
        companyJobs: cCompanyJob,
        unbookmarkingCompanyJob: false,
        unbookmarkingCompanyJobError: false,
      };
    }

    case types.UNBOOKMARK_COMPANY_JOB_FAILED: {
      return {
        ...state,
        unbookmarkingCompanyJob: false,
        unbookmarkingCompanyJobError: true,
      };
    }

    // For discover companies
    case types.FETCHING_COMPANY_SUGGESTION: {
      return {
        ...state,
        fetchingDiscoverCompanies: true,
      };
    }
    case types.FETCHING_COMPANY_SUGGESTION_SUCCEED: {
      return {
        ...state,
        discoverCompanies: action.payload,
        fetchingDiscoverCompanies: false,
      };
    }
    case types.FETCHING_COMPANY_SUGGESTION_FAILED: {
      return {
        ...state,
        fetchingDiscoverCompanies: false,
      };
    }

    // for discover more companies
    case types.FETCH_DISCOVER_COMPANIES: {
      return {
        ...state,
        fetchingCPDiscoverMoreCompany: true,
      };
    }
    case types.FETCH_DISCOVER_COMPANIES_SUCCEED: {
      return {
        ...state,
        cpDiscoverMoreCompanies: action.payload,
        fetchingCPDiscoverMoreCompany: false,
        fetchingCPDiscoverMoreCompanyError: false,
      };
    }
    case types.FETCH_DISCOVER_COMPANIES_FAILED: {
      return {
        ...state,
        fetchingCPDiscoverMoreCompany: false,
        fetchingCPDiscoverMoreCompanyError: true,
      };
    }

    // for company affiliates
    case types.FETCH_AFFILIATE_COMPANY: {
      return {
        ...state,
        fetchingCompanyAffiliates: true,
      };
    }
    case types.FETCH_AFFILIATE_COMPANY_SUCCEED: {
      return {
        ...state,
        companyAffiliates: action.payload,
        fetchingCompanyAffiliates: false,
        fetchingCompanyAffiliatesError: false,
      };
    }
    case types.FETCH_AFFILIATE_COMPANY_FAILED: {
      return {
        ...state,
        fetchingCompanyAffiliates: false,
        fetchingCompanyAffiliatesError: true,
      };
    }

    // For company job
    case types.FETCH_COMPANY_JOBS: {
      return {
        ...state,
        fetchingCompanyJob: true,
      };
    }
    case types.FETCH_COMPANY_JOBS_SUCCEED: {
      return {
        ...state,
        companyJobs: action.payload,
        fetchingCompanyJob: false,
        fetchingCompanyJobError: false,
      };
    }
    case types.FETCH_COMPANY_JOBS_FAILED: {
      return {
        ...state,
        fetchingCompanyJob: false,
        fetchingCompanyJobError: true,
      };
    }

    // For static companies
    case types.FETCH_STATIC_COMPANIES_LIST: {
      return {
        ...state,
        fetchingStaticCompanies: true,
      };
    }
    case types.FETCH_STATIC_COMPANIES_LIST_SUCCEED: {
      return {
        ...state,
        staticCompanies: action.payload,
      };
    }
    case types.FETCH_STATIC_COMPANIES_LIST_FAILED: {
      return {
        ...state,
        fetchingStaticCompanies: false,
      };
    }

    // For homepage hiring companies
    case types.FETCH_HOMEPAGE_HIRING_COMPANIES_SUCCEED: {
      return {
        ...state,
        homepageHiringCompanies: action.companies,
        fetchingHomepageHiringCompanies: false,
      };
    }
    case types.FETCH_HOMEPAGE_HIRING_COMPANIES_FAILED: {
      return {
        ...state,
        fetchingHomepageHiringCompanies: false,
      };
    }

    // For company page banner
    case types.FETCH_COMPANY_BANNER_SUCCEED: {
      return {
        ...state,
        companyPageBanner: action.banner ?? {},
      };
    }

    case types.UPDATE_COMPANY_SEARCH_KEYWORD: {
      return {
        ...state,
        companyFilter: {
          ...state.companyFilter,
          keyword: action.keyword,
        },
      };
    }

    case types.FETCH_EMPLOYER_TRUSTED_BRAND: {
      return {
        ...state,
        employerTrustedLogos: action.logos,
      };
    }

    case types.FETCH_EMPLOYER_TESTIMONIALS: {
      return {
        ...state,
        employerTestimonials: action.testimonials,
      };
    }

    case types.FETCH_EMPLOYER_EXTERNAL_JOB_BOARD_SUCCEED: {
      return {
        ...state,
        externalJobBoard: action.payload ?? {},
      };
    }

    default: {
      return state;
    }
  }
};
