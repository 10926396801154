// Font
export const MUITypography = {
  fontFamily: ["Inter", "sans-serif"].join(","),
  h1_regular_L: {
    fontSize: "3.75rem", // 60px
    fontWeight: "400",
  },
  h1_semiBold_L: {
    fontSize: "3.75rem", // 60px
    fontWeight: "600",
  },
  h1_bold_L: {
    fontSize: "3.75rem", // 60px
    fontWeight: "bold",
  },
  h2_regular_L: {
    fontSize: "2.625rem", // 42px
    fontWeight: "400",
  },
  h2_semiBold_L: {
    fontSize: "2.625rem", // 42px
    fontWeight: "600",
  },
  h2_bold_L: {
    fontSize: "2.625rem", // 42px
    fontWeight: "bold",
  },
  h3_regular_L: {
    fontSize: "2rem", // 32px
    fontWeight: "400",
  },
  h3_semiBold_L: {
    fontSize: "2rem", // 32px
    fontWeight: "600",
  },
  h3_bold_L: {
    fontSize: "2rem", // 32px
    fontWeight: "bold",
  },
  h4_regular_L: {
    fontSize: "1.563rem", // 25px
    fontWeight: "400",
  },
  h4_semiBold_L: {
    fontSize: "1.563rem", // 25px
    fontWeight: "600",
  },
  h4_bold_L: {
    fontSize: "1.563rem", // 25px
    fontWeight: "bold",
  },
  h5_regular_L: {
    fontSize: "1.25rem", // 20px
    fontWeight: "400",
  },
  h5_semiBold_L: {
    fontSize: "1.25rem", // 20px
    fontWeight: "600",
  },
  h5_bold_L: {
    fontSize: "1.25rem", // 20px
    fontWeight: "bold",
  },
  subtitle1_L: {
    fontSize: "1rem", // 16px
    fontWeight: "400",
  },
  subtitle2_L: {
    fontSize: "0.875rem", // 14px
    fontWeight: "500",
  },
  body1_L: {
    fontSize: "1rem", // 16px
    fontWeight: "400",
  },
  body1_light_L: {
    fontSize: "1rem", // 16px
    fontWeight: "300",
  },
  body1_bold_L: {
    fontSize: "1rem", // 16px
    fontWeight: "700",
  },
  body2_L: {
    fontSize: "0.875rem", // 14px
    fontWeight: "400",
  },
  body2_light_L: {
    fontSize: "1rem", // 16px
    fontWeight: "300",
  },
  body2_bold_L: {
    fontSize: "0.875rem", // 14px
    fontWeight: "700",
  },
  body2_S: {
    fontSize: "0.75rem", // 12px
    fontWeight: "400",
  },
  h1_S: {
    fontSize: "2.125rem", // 34px
    fontWeight: "700",
  },
  title_S: {
    fontSize: "1.25rem", // 20px
    fontWeight: "700",
  },
  body_S: {
    fontSize: "0.875rem", // 14px
    fontWeight: "400",
  },
  body_light_S: {
    fontSize: "0.875rem", // 14px
    fontWeight: "300",
  },
  body_bold_S: {
    fontSize: "0.875rem", // 14px
    fontWeight: "700",
  },
  custom_italic_L: {
    fontSize: "1.25rem", // 20px
    fontWeight: "500",
    fontStyle: "italic",
    lineHeight: "28px",
  },
  custom_italic_S: {
    fontSize: "1rem", // 16px
    fontWeight: "400",
    fontStyle: "italic",
    lineHeight: "24px",
  },
};
