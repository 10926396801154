import {
  faBook,
  faBuilding,
  faBusinessTime,
  faDesktop,
  faLaptopHouse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import SchoolIcon from "@mui/icons-material/School";

export const currencies = {
  MY: "RM",
  SG: "SGD",
};

export const profileOverviewSections = [
  {
    id: 1,
    sectionName: "Work Experience",
    visible: true,
  },
  {
    id: 2,
    sectionName: "Education",
    visible: true,
  },
  {
    id: 3,
    sectionName: "Projects",
    visible: true,
  },
  {
    id: 4,
    sectionName: "Certificates and Training",
    visible: true,
  },
  {
    id: 5,
    sectionName: "Honours and Awards",
    visible: true,
  },
  {
    id: 6,
    sectionName: "Extracurricular",
    visible: true,
  },
  {
    id: 7,
    sectionName: "Language",
    visible: true,
  },
  {
    id: 8,
    sectionName: "Skills",
    visible: true,
  },
];

export const jobCategoryFilters = [
  {
    id: 1,
    title: "Fresh Graduate Jobs",
    icon: <SchoolIcon fontSize="inherit" />,
    url: `/jobs?experience=2`,
    element_id: "trending-categories-fresh-grad",
  },
  {
    id: 2,
    title: "Internships",
    icon: <FontAwesomeIcon icon={faBook} />,
    url: `/internship-jobs`,
    element_id: "trending-categories-internship",
  },
  {
    id: 3,
    title: "IT Jobs",
    icon: <FontAwesomeIcon icon={faDesktop} />,
    url: `/jobs-in-information-technology`,
    element_id: "trending-categories-it",
  },
  {
    id: 4,
    title: "Jobs in Klang Valley",
    icon: <FontAwesomeIcon icon={faBuilding} />,
    url: `/jobs?state=Kuala+Lumpur&state=Selangor&state=Putrajaya`,
    element_id: "trending-categories-klang-valley",
  },
  {
    id: 5,
    title: "Creative Jobs",
    icon: <ColorLensIcon />,
    url: `/jobs-in-creative`,
    element_id: "trending-categories-creative",
  },
  {
    id: 6,
    title: "Part-Time Jobs",
    icon: <FontAwesomeIcon icon={faBusinessTime} />,
    url: `/part-time-jobs`,
    element_id: "trending-categories-part-time",
  },
  {
    id: 7,
    title: "Remote Working Jobs",
    icon: <FontAwesomeIcon icon={faLaptopHouse} />,
    url: `/remote-jobs`,
    element_id: "trending-categories-remote",
  },
];

export const proficiency = ["Conversational", "Professional", "Native"];

export const languages = [
  "Afrikaans",
  "Arabic",
  "Bangla",
  "Bulgarian",
  "Burmese",
  "Cantonese",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hokkien",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Khmer",
  "Korean",
  "Lao",
  "Malay",
  "Norwegian",
  "Persian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Spanish",
  "Swedish",
  "Tagalog",
  "Tamil",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
];

export const nationalities = [
  "Malaysian",
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Antiguans",
  "Argentinean",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Barbudans",
  "Batswana",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Brazilian",
  "British",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape Verdean",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa Rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djibouti",
  "Dominican",
  "Dutch",
  "East Timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirian",
  "Equatorial Guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinea-Bissauan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Herzegovinian",
  "Honduran",
  "Hungarian",
  "I-Kiribati",
  "Icelander",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakhstani",
  "Kenyan",
  "Kittian and Nevisian",
  "Kuwaiti",
  "Kyrgyz",
  "Laotian",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtensteiner",
  "Lithuanian",
  "Luxembourger",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Maldivian",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Moroccan",
  "Mosotho",
  "Motswana",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepalese",
  "New Zealander",
  "Ni-Vanuatu",
  "Nicaraguan",
  "Nigerian",
  "Nigerien",
  "North Korean",
  "Northern Irish",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Panamanian",
  "Papua New Guinean",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Saint Lucian",
  "Salvadoran",
  "Samoan",
  "San Marinese",
  "Sao Tomean",
  "Saudi",
  "Scottish",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra Leonean",
  "Singaporean",
  "Slovakian",
  "Slovenian",
  "Solomon Islander",
  "Somali",
  "South African",
  "South Korean",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Surinamer",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tongan",
  "Trinidadian or Tobagonian",
  "Tunisian",
  "Turkish",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Uzbekistani",
  "Venezuelan",
  "Vietnamese",
  "Welsh",
  "Yemenite",
  "Zambian",
  "Zimbabwean",
  "Not Listed",
];

export const institutions = [
  "Akademi Seni Budaya Dan Warisan Kebangsaan (ASWARA)",
  "Al-Madinah International University (MEDIU)",
  "Asia e University (AeU)",
  "Asia Metropolitan University (AMU)",
  "Asia Pacific University of Technology & Innovation (APU)",
  "Asia School of Business (ASB)",
  "Asian Institute of Medical Science and Technology (AIMST University)",
  "Binary University of Management & Entrepreneurship (BUME)",
  "City University Malaysia",
  "Curtin University Malaysia",
  "GlobalNxt University",
  "HELP University",
  "Heriot-Watt University Malaysia",
  "Infrastructure University KL (IUKL)",
  "International Centre for Education in Islamic Finance (INCEIF)",
  "International Islamic University Malaysia (IIUM)",
  "International Medical College (IMC)",
  "International Medical University (IMU)",
  "International University of Malaya-Wales (IUMW)",
  "INTI International University",
  "University of Wollongong KDU Malaysia",
  "Kampus Cawangan Malaysian Institute of Aviation Technology (UNIKL-MIAT)",
  "Kolej Universiti Islam Antarabangsa Selangor (KUIS)",
  "Limkokwing University of Creative Technology (LUCT)",
  "Lincoln University College",
  "MAHSA University",
  "Malaysia University of Science and Technology (MUST)",
  "Malaysian Institute For Supply Chain Innovation (MISI)",
  "Management and Science University (MSU)",
  "Manipal International University (MIU)",
  "Meritus University",
  "Monash University Malaysia",
  "Multimedia University (MMU)",
  "Nilai University",
  "Newcastle University Medicine Malaysia",
  "Open University Malaysia (OUM)",
  "Perdana University",
  "Politeknik Balik Pulau",
  "Politeknik Banting",
  "Politeknik Hulu Terengganu",
  "Politeknik Ibrahim Sultan",
  "Politeknik Jeli",
  "Politeknik Kota Bharu",
  "Politeknik Kota Kinabalu",
  "Politeknik Kuala Terengganu",
  "Politeknik Kuching Sarawak",
  "Politeknik Melaka",
  "Politeknik Merlimau",
  "Politeknik Mersing",
  "Politeknik Muadzam Shah",
  "Politeknik Mukah Sarawak",
  "Politeknik Nilai",
  "Politeknik Port Dickson",
  "Politeknik Sandakan",
  "Politeknik Seberang Perai",
  "Politeknik Sultan Abdul Halim Mu'adzam Shah",
  "Politeknik Sultan Azlan Shah",
  "Politeknik Sultan Haji Ahmad Shah",
  "Politeknik Sultan Idris Shah",
  "Politeknik Sultan Mizan Zainal Abidin",
  "Politeknik Sultan Salahuddin Abdul Aziz Shah",
  "Politeknik Tuanku Sultanah Bahiyah",
  "Politeknik Tuanku Syed Sirajuddin",
  "Politeknik Tun Syed Nasir Syed Ismail",
  "Politeknik Ungku Omar",
  "Quest International University Perak (QIUP)",
  "Raffles University (Raffles)",
  "SEGI University (SEGI)",
  "Sunway University",
  "Swinburne University of Technology Sarawak",
  "Taylor's University",
  "UCSI University",
  "UNITAR International University (UNITAR)",
  "Universiti Antarabangsa AlBukhary (AIU)",
  "Universiti Islam Antarabangsa Malaysia (UIAM)",
  "Universiti Islam Malaysia (UIM)",
  "Universiti Kebangsaan Malaysia (UKM)",
  "Universiti Kuala Lumpur (UNIKL)",
  "Universiti Kuala Lumpur Kampus Cawangan British Malaysia Institute (UNIKL-BMI)",
  "Universiti Kuala Lumpur Kampus Cawangan Institute of Medical Science (UNIKL-MESTEC)",
  "Universiti Kuala Lumpur Kampus Cawangan Malaysian France Institute (UNIKL- MFI)",
  "Universiti Kuala Lumpur Kampus Cawangan Malaysian Institute of Chemical & Bio-Engineering Technology (UNIKL-MICET)",
  "Universiti Kuala Lumpur Kampus Cawangan Malaysian Institute of Industrial Technology (UNIKL-MITEC)",
  "Universiti Kuala Lumpur Kampus Cawangan Malaysian Institute of Marine Engineering Technology (UNIKL-MIMET)",
  "Universiti Kuala Lumpur Kampus Cawangan Malaysian Spanish Institute (UNIKL-MSI)",
  "Universiti Kuala Lumpur Kampus Cawangan Royal College of Medicine Perak (UniKL RCMP)",
  "Universiti Malaya (UM)",
  "Universiti Malaysia Kelantan (UMK)",
  "Universiti Malaysia Pahang (UMP)",
  "Universiti Malaysia Perlis (UniMAP)",
  "Universiti Malaysia Sabah (UMS)",
  "Universiti Malaysia Sarawak (UNIMAS)",
  "Universiti Malaysia Terengganu (UMT)",
  "Universiti Malaysia Sabah Kampus Antarabangsa Labuan (UMS-KAL)",
  "University Malaysia of Computer Science and Engineering (UniMY)",
  "Universiti Pendidikan Sultan Idris (UPSI)",
  "Universiti Pertahanan Nasional Malaysia (UPNM)",
  "Universiti Putra Malaysia (UPM)",
  "Universiti Sains Islam Malaysia (USIM)",
  "Universiti Sains Malaysia (USM)",
  "Universiti Selangor (UNISEL)",
  "Universiti Sultan Azlan Shah (USAS)",
  "Universiti Sultan Zainal Abidin (UniSZA)",
  "Universiti Teknikal Malaysia Melaka (UTeM)",
  "Universiti Teknologi Malaysia (UTM)",
  "Universiti Teknologi MARA (UiTM)",
  "Universiti Teknologi Petronas (UTP)",
  "Universiti Tenaga Nasional (UNITEN)",
  "Universiti Terbuka Wawasan (WOU)",
  "Universiti Tun Abdul Razak (UNIRAZAK)",
  "Universiti Tun Hussein Onn Malaysia (UTHM)",
  "Universiti Tunku Abdul Rahman (UTAR)",
  "Universiti Utara Malaysia (UUM)",
  "University of Cyberjaya (UoC)",
  "University of Hertfordshire Malaysia",
  "University of Nottingham Malaysia",
  "University of Reading Malaysia",
  "University of Southampton Malaysia",
  "Xiamen University Malaysia",
];

export const educationLevels = [
  "Secondary School",
  "Pre-University (A-Levels, Foundation, Diploma or equivalent)",
  "Undergraduate (Bachelor's Degree or equivalent)",
  "Postgraduate (Master's Degree or equivalent or higher)",
  "Other",
];

export const fields = [
  "Business",
  "Creative Arts, Design, Architecture",
  "Engineering",
  "Health Science",
  "Hospitality, Tourism and Culinary",
  "IT",
  "Law",
  "Social Science",
];

export const genders = ["Male", "Female"];

export const interestedJobTitles = [
  // Accounting & Finance
  "Accountant",
  "Accounting/Finance Clerk",
  "Auditor",
  "Financial Analyst",
  "Actuary",
  "Controller",
  "Risk Consultant",
  "Treasurer",
  "Economist",
  // Creative
  "Graphic Designer",
  "Videographer",
  "Photographer",
  "Interior Designer",
  "Illustrator",
  "Instructional Designer",
  "Web Designer",
  "Industrial Designer",
  "Game Designer",
  "UX/UI Designer",
  "Editor",
  "Content Strategist",
  "Sound Engineer",
  "Animator",
  "Musician",
  "Writer",
  // Education
  "Academic Adviser/Coordinator",
  "Professor",
  "Coach/Teacher",
  "Program Coordinator",
  "Teaching Assistant",
  // Engineering
  "Chemical Engineer",
  "Electrical Engineer",
  "Electronics Engineer",
  "Environmental Engineer",
  "Civil Engineer",
  "Industrial Engineer",
  "Mechanical Engineer",
  "Oil/Gas Engineer",
  // Human Resources
  "Recruiter",
  "Human Resources Analyst",
  "Recruiting Coordinator",
  "Human Resources Associate",
  // Information Technology
  "Cloud Computing Engineer",
  "Computer Network Specialist/Engineer",
  "Computer Support Specialist",
  "System Analyst",
  "Information Security Specialist",
  "Software/Application/Web Developer",
  "Data Analyst",
  "Data Engineer",
  "Data Scientist",
  "DevOps Engineer",
  "Quality Assurance Analyst",
  // Legal
  "Legal Assistant",
  "Patent Agent",
  "Contract Administrator",
  "Lawyer",
  "Legal Analyst/Consultant",
  "Legal Writer",
  // Healthcare
  "Nurse",
  "Doctor",
  "Physical Therapist",
  "Pharmacist",
  "Medical Administrator",
  "Psychologist",
  // Marketing
  "Marketing Associate",
  "Marketing Coordinator",
  "Digital Marketing Associate",
  "Brand Ambassador",
  "Marketing Analyst",
  "Marketing Consultant",
  "Copywriter",
  // Operations & Admin
  "Administrator",
  "Secretary",
  "Account/Billing Coordinator",
  "Data Entry",
  "Support Specialist",
  "Receptionist",
  "Customer Service",
  // Hospitality
  "Event Coordinator",
  "Event Planner",
  "Chef/Pastry Chef",
  "Attendant",
  "Barista",
  "Waiter/Waitress",
  // Sales
  "Sales Associate/Consultant",
  "Sales Development Representative",
  "Inside Sales Representative",
  "Outside Sales Representative",
  "Business Development Representative",
  // Supply Chain and Logistics
  "Demand Planning Specialist",
  "Warehouse Assistant",
  "Logistics Management",
  "Purchasing Assistant",
  "Supply Specialist/Coordinator",
  "Driver",
  "Logistics Coordinator/Associate",
  "Supply Chain Associate",
];

export const CHAT_CHANNEL_HANDLER_ID = "CHAT_HANDLER";
export const CHAT_LIST_CHANNEL_HANDLER_ID = "CHAT_LIST_HANDLER";

// for SEO static page
// slug is capitalized to match query params
export const experienceLevels = [
  {
    experienceLevel: "Intern",
    slug: "intern",
  },
  {
    experienceLevel: "Fresh Graduate",
    slug: "fresh-graduate",
  },
  {
    experienceLevel: "Junior Executive", // 1 to 3 years of experience
    slug: "junior-executive",
  },
  {
    experienceLevel: "Senior Executive", // 4 to 7 years of experience
    slug: "senior-executive",
  },
  {
    experienceLevel: "Manager", // 8 to 10 years of experience
    slug: "manager",
  },
  {
    experienceLevel: "Senior Manager", // over 10 years of experience
    slug: "senior-manager",
  },
];

// for SEO static page
// slug is capitalized to match query params
export const stateRegions = [
  {
    stateRegion: "Kuala Lumpur",
    slug: "kuala-lumpur",
  },
  {
    stateRegion: "Selangor",
    slug: "selangor",
  },
  {
    stateRegion: "Johor",
    slug: "johor",
  },
  {
    stateRegion: "Kedah",
    slug: "kedah",
  },
  {
    stateRegion: "Kelantan",
    slug: "kelantan",
  },
  {
    stateRegion: "Malacca",
    slug: "malacca",
  },
  {
    stateRegion: "Negeri Sembilan",
    slug: "negeri-sembilan",
  },
  {
    stateRegion: "Pahang",
    slug: "pahang",
  },
  {
    stateRegion: "Penang",
    slug: "penang",
  },
  {
    stateRegion: "Perak",
    slug: "perak",
  },
  {
    stateRegion: "Perlis",
    slug: "perlis",
  },
  {
    stateRegion: "Sabah",
    slug: "sabah",
  },
  {
    stateRegion: "Sarawak",
    slug: "sarawak",
  },
  {
    stateRegion: "Terengganu",
    slug: "terengganu",
  },
  {
    stateRegion: "Overseas",
    slug: "overseas",
  },
];

// for SEO static page
// slug is capitalized to match query params
export const jobTypes = [
  {
    id: 5,
    jobType: "Freelance",
    slug: "freelance",
  },
  {
    id: 4,
    jobType: "Internship",
    slug: "internship",
  },
  {
    id: 3,
    jobType: "Part-Time",
    slug: "part-time",
  },
  {
    id: 2,
    jobType: "Full-Time",
    slug: "full-time",
  },
];

// for SEO static page
export const specialisations = [
  {
    track: "Creative",
    specialisation: "Creative - Videography & Photography",
    slug: "creative-videography-photography-jobs",
  },
  {
    track: "Creative",
    specialisation: "Creative - Visual Design",
    slug: "creative-visual-design-jobs",
  },
  {
    track: "Creative",
    specialisation: "Creative - Writing",
    slug: "creative-writing-jobs",
  },
  {
    track: "Marketing",
    specialisation: "Marketing - Digital",
    slug: "digital-marketing-jobs",
  },
  {
    track: "Marketing",
    specialisation: "Marketing - General",
    slug: "marketing-jobs",
  },
  {
    track: "IT",
    specialisation: "IT - Data Science & Analytics",
    slug: "it-data-science-analytics-jobs",
  },
  {
    track: "IT",
    specialisation: "IT - General",
    slug: "it-jobs",
  },
  {
    track: "IT",
    specialisation: "IT - Software Development",
    slug: "it-software-development-jobs",
  },
  {
    track: "Sales",
    specialisation: "Sales",
    slug: "sales-jobs",
  },
  {
    track: "Customer Service",
    specialisation: "Customer Service",
    slug: "customer-service-jobs",
  },
  {
    track: "Accounting & Finance",
    specialisation: "Accounting & Finance",
    slug: "accounting-finance-jobs",
  },
  {
    track: "Ops & Admin",
    specialisation: "Operations & Admin",
    slug: "operations-admin-jobs",
  },
  {
    track: "Engineering",
    specialisation: "Engineering",
    slug: "engineering-jobs",
  },
  {
    track: "HR",
    specialisation: "Human Resources",
    slug: "human-resources-jobs",
  },
  {
    track: "Management Trainee",
    specialisation: "Management Trainee",
    slug: "management-trainee-jobs",
  },
  {
    track: "Education",
    specialisation: "Education",
    slug: "education-jobs",
  },
  {
    track: "Supply Chain & Logistics",
    specialisation: "Supply Chain & Logistics",
    slug: "supply-chain-logistics-jobs",
  },
  {
    track: "Retail",
    specialisation: "Retail",
    slug: "retail-jobs",
  },
  {
    track: "Project Management",
    specialisation: "Project Management",
    slug: "project-management-jobs",
  },
  {
    track: "Legal",
    specialisation: "Legal",
    slug: "legal-jobs",
  },
  {
    track: "Other",
    specialisation: "Other",
    slug: "other-jobs",
  },
];

export const months = [
  { label: "Jan", id: 1 },
  { label: "Feb", id: 2 },
  { label: "Mar", id: 3 },
  { label: "Apr", id: 4 },
  { label: "May", id: 5 },
  { label: "Jun", id: 6 },
  { label: "Jul", id: 7 },
  { label: "Aug", id: 8 },
  { label: "Sep", id: 9 },
  { label: "Oct", id: 10 },
  { label: "Nov", id: 11 },
  { label: "Dec", id: 12 },
];

// ? For sitemap generation
// ? Total number of locations = 94
export const locations = [
  "wfh",
  "remote",
  "work from home",
  "Johor",
  "Kedah",
  "Kelantan",
  "Malacca",
  "Negeri Sembilan",
  "Pahang",
  "Perak",
  "Perlis",
  "Penang",
  "Sabah",
  "Sarawak",
  "Selangor",
  "Terengganu",
  "Ampang",
  "Bandar Damai Perdana",
  "Bandar Menjalara",
  "Bandar Tasik Selatan",
  "Bangsar",
  "Bangsar South",
  "Brickfields",
  "Bukit Bintang",
  "Bukit Jalil",
  "Cheras",
  "City Centre",
  "Damansara",
  "Desa Pandan",
  "Desa ParkCity",
  "Desa Petaling",
  "Federal Hill",
  "Gombak",
  "Jalan Ipoh",
  "Jalan Klang Lama",
  "Jalan Kuching",
  "Jalan Sultan Ismail",
  "Jinjang",
  "Kepong",
  "Keramat",
  "KL City",
  "KL Sentral",
  "KLCC",
  "Kuchai Lama",
  "Mid Valley City",
  "Mont Kiara",
  "Old Klang Road",
  "OUG",
  "Pandan Indah",
  "Pandan Jaya",
  "Pandan Perdana",
  "Pantai",
  "Pekan Batu",
  "Puchong",
  "Segambut",
  "Sentul",
  "Seputeh",
  "Serdang",
  "Setapak",
  "Setiawangsa",
  "Solaris Dutamas",
  "Solaris Mont Kiara",
  "Sri Hartamas",
  "Sri Petaling",
  "Sunway",
  "Sungai Besi",
  "Sungai Penchala",
  "Taman Desa",
  "Taman Duta",
  "Taman Melawati",
  "Taman Tun Dr Ismail",
  "TTDI",
  "Titiwangsa",
  "Wangsa Maju",
  "Subang",
  "Sentral",
  "Taman Tun Dr. Ismail",
  "Damansara Utama",
  "Damansara Perdana",
  "Damansara Jaya",
  "Kota Damansara",
  "Bandar Utama",
  "SS2",
  "Kelana Jaya",
  "Ara Damansara",
  "SS15",
  "USJ",
  "Subang Jaya Industrial Estate",
  "SS14",
  "SS18",
  "SS17",
  "SS16",
  "Bandar Sunway",
  "Sunway Pyramid",
];

// ? For sitemap generation
// ? Total number of keywords = 1,338
export const keywords = [
  "Graphic Designer",
  "Sales",
  "Marketing",
  "Business Development",
  "Digital Marketing",
  "Account",
  "Finance",
  "Admin",
  "Accounts",
  "Software Engineer",
  "Customer Service",
  "Marketing Intern",
  "Web Developer",
  "Project",
  "HR",
  "Interior Designer",
  "Operations",
  "Social Media",
  "Software Developer",
  "Sales-Marketing",
  "Creative Designer",
  "Copywriter",
  "Digital Marketing Intern",
  "UI-UX Designer",
  "Human Resource",
  "Internship",
  "Digital Marketing Specialist",
  "ECommerce",
  "Full Stack Developer",
  "Sales Consultant",
  "Graphic Design Intern",
  "Content Writer",
  "Product",
  "Personal",
  "Administrative",
  "Accountant",
  "Multimedia Designer",
  "Sales Coordinator",
  "Purchasing",
  "Business Development Intern",
  "Front End Developer",
  "Business Analyst",
  "Sales and Marketing",
  "Human Resources",
  "Web Designer",
  "Java Developer",
  "PHP Developer",
  "Intern",
  "Key Account",
  "Art Director",
  "IT",
  "Video Editor",
  "Digital Marketer",
  "Finance Intern",
  "Procurement",
  "Event",
  "Digital Designer",
  "Sales Engineer",
  "Internship for Marketing",
  "Operation",
  "Sales Representative",
  "Social Media Intern",
  "Marketing Specialist",
  "Internship for Graphic Design",
  "Customer Service Representative",
  "Warehouse",
  "Customer Success",
  "Digital Account",
  "Corporate Sales",
  "Performance Marketing Specialist",
  "Internship for Business Development",
  "Management Trainee",
  "Marketing Internship",
  "Quantity Surveyor",
  "HR-Admin",
  "HR Intern",
  "SEO Specialist",
  "Content Creator",
  "Operations Intern",
  "Social Media Specialist",
  "Brand",
  "Telemarketing",
  "Designer",
  "Social Media Marketing",
  "Sales Admin",
  "Project Engineer",
  "Internship for Kitchen Crew",
  "Sales Development Representative",
  "Sales Intern",
  "Accounting",
  "Data Analyst",
  "Internship for Operation",
  "Performance Marketing",
  "Motion Graphic Designer",
  "Backend Developer",
  "Client Servicing",
  "Community",
  "Graphic Designer Intern",
  "Talent Acquisition Specialist",
  "Telesales",
  "IOS Developer",
  "DotNET Developer",
  "Android Developer",
  "DevOps Engineer",
  "Digital Marketing Internship",
  "Frontend Developer",
  "Intern Sales Marketing",
  "Retail",
  "Technician",
  "Internship for Accounting",
  "Relationship",
  "Mobile App Developer",
  "Project Coordinator",
  "Data Scientist",
  "Internship Producer",
  "Programmer",
  "Customer Service Officer",
  "Internship for Culinary-Hospitality",
  "Sales executive",
  "Customer Support",
  "Retail Supervisor",
  "Human Resource Intern",
  "Inside Sales",
  "Data Engineer",
  "Site Supervisor",
  "IT Intern",
  "Social Media Marketing Intern",
  "Account Cum Admin",
  "Business Admin",
  "IT Internship Hybrid",
  "Marketing-SEO-Public Relation Internship Hybrid",
  "Sales Account",
  "Talent Acquisition",
  "Entrepreneur Internship Hybrid",
  "IT Project",
  "Legal",
  "Management Internship Hybrid",
  "Sales Administrator",
  "Graphic Design Internship",
  "Internship for Digital marketing",
  "Internship for Media-Art Students",
  "Marketing Executive",
  "Supply Chain",
  "Content Writer Internship",
  "Event Management-Operation Internship Hybrid",
  "Web Developer Internship",
  "Admin Intern",
  "Mobile Developer",
  "Recruiter",
  "System Engineer",
  "Business Development Associate",
  "Customer Experience",
  "Human Resources Intern",
  "Internship for Sales-Operation",
  "Mobile Application Developer",
  "Project Manager",
  "Startup Accelerator-Investment Internship Hybrid",
  "Business Consultant",
  "Internship for Computer-IT students",
  "Investment Analyst Internship Hybrid",
  "Office Administrator",
  "Product Specialist",
  "Accounts-Admin",
  "Events",
  "Finance-Admin",
  "Graphic Designer Internship",
  "Head of Marketing",
  "Inside Sales Representative",
  "Intern Social Media Allowance",
  "Internship for Multimedia Design",
  "Receptionist",
  "Retail Sales",
  "System Administrator",
  "Tenancy",
  "Videographer",
  "Finance",
  "Marketing",
  "Electrical Engineer",
  "Finance Internship",
  "Production",
  "React Native Developer",
  "Sales Associate",
  "Sales Support",
  "UI UX Designer",
  "Admin Clerk",
  "Admin Internship Hybrid",
  "Customer Care",
  "Design Consultant Sales",
  "Digital Marketing Strategist",
  "IT Support",
  "Retail Associate",
  "Technical Support Engineer",
  "Telemarketer",
  "Accounting Intern",
  "Creative Copywriter",
  "Financial Advisor",
  "Internship for Data Analysis",
  "Internship for Human Resource",
  "Real Estate Negotiator with Free FB HOT Leads",
  "Sales Development Representative Remote",
  "Accounts cum Admin",
  "Administration",
  "Human Resources Internship Hybrid",
  "Operations Associate",
  "Real Estate Negotiator",
  "Recruitment Specialist",
  "Technical Support",
  "UI Designer",
  "Account-Admin",
  "Accounting Internship Hybrid",
  "Audit",
  "Business Development Internship Remote",
  "Sales-Marketing Intern",
  "Social Media Content Creator",
  "UX Designer",
  "Analyst",
  "Business Development Internship Remote",
  "Corporate Projects",
  "ECommerce Intern",
  "Graphics Designer",
  "HR Generalist",
  "Interior Styling Designer",
  "Internship for Customer Service",
  "Internship for Kitchen Management",
  "Internship for Sales-Marketing Students",
  "Logistics",
  "Project Management Intern",
  "QA Engineer",
  "SEO",
  "Business Intelligence Analyst",
  "Business Support",
  "Ecommerce Marketing",
  "Event Executive",
  "Internal Audit",
  "Marketing Associate",
  "Media Sales",
  "Personal to Director",
  "PHP Programmer",
  "Public Relations",
  "Sales Designer",
  "Account Executive",
  "Admin Internship",
  "Back End Developer",
  "Copywriter Intern",
  "Internship for Accounting Students",
  "Internship for Business Development-Strategy",
  "Merchandiser",
  "Network Engineer",
  "Receptionist cum Admin",
  "Recruitment",
  "Service Engineer",
  "Administrator",
  "Application Engineer",
  "Customer Experience Specialist",
  "Design Intern",
  "Full Stack Web Developer",
  "PHP Web Developer",
  "PR",
  "Product Development",
  "Recruitment Consultant",
  "Sales Specialist",
  "Web Application Developer",
  "B2B Content Writer",
  "Business Development Executive",
  "Business Development Internship",
  "Content Strategist",
  "Creative Director",
  "Digital Content Creator",
  "Intern Social Media-Marketing",
  "Internship for Food and Beverage Preparation Management",
  "IT Internship",
  "Personal Driver",
  "Sales Advisor",
  "Tax",
  "Trade Marketing",
  "Warehouse Supervisor",
  "WordPress Developer",
  "Writer",
  "Business Development Consultant",
  "Content",
  "eCommerce Marketing Internship",
  "HR Admin",
  "HR Internship",
  "Internship for Business Studies-Administration Students",
  "Online Marketing",
  "Real Estate Negotiator Basic Salary and Commission",
  "Sales and Marketing Intern",
  "Secretary",
  "Software Engineer Intern",
  "Unit Trust Consultant",
  "UX-UI Designer",
  "Account Client Servicing",
  "Business Development Lead",
  "Customer Happiness",
  "Customer Service Specialist",
  "Developer",
  "Digital Project",
  "Digital Sales",
  "HR and Admin",
  "Human Resource Internship",
  "Management Associate",
  "Mobile Apps Developer",
  "Project Supervisor",
  "Real Estate Negotiator with Free Facebook HOT Lead",
  "System Analyst",
  "Technical Writer",
  "Web Developer Intern",
  "Admin cum Account",
  "Application Developer",
  "Brand Marketing",
  "Company Secretary",
  "Copywriter-Copywriter",
  "Event Coordinator",
  "Finance and Admin",
  "Handyman",
  "HR cum Admin",
  "Merchandising",
  "Multimedia Designer Intern",
  "Production Planner",
  "Strategy Consultant Sales",
  "Technical",
  "UI-UX Designer",
  "Young Graduate",
  "Digital Marketing",
  "Administrative Intern",
  "Content Marketing",
  "Content Writer Intern",
  "Copywriter Internship",
  "Customer Service Intern",
  "Digital Copywriter",
  "Digital Marketing Executive",
  "ECommerce Customer Service",
  "ECommerce Specialist",
  "Event Manager",
  "Financial Analyst",
  "Human Resources Internship",
  "Internal Auditor",
  "Internship for Marketing Customer Service",
  "Internship Human Resource",
  "Key Accounts Sales",
  "Marketing Automation Lead Hubspot",
  "Photographer",
  "Producer",
  "Product Designer",
  "Senior Digital Marketing Executive",
  "Software Tester",
  "UIUX Designer",
  "Video Production Intern",
  "Videographer cum Video Editor",
  "Wealth Planner",
  "Branch",
  "Conference Producer",
  "Creative",
  "Creative Intern",
  "Customer Care Representative",
  "Customer Success Specialist",
  "Database Administrator",
  "Digital Content Writer",
  "Financial Planner",
  "Human Resources-Admin Internship Hybrid",
  "Interior Styling Intern",
  "Internship Digital Marketing",
  "Internship Retail Operation",
  "Office Admin",
  "People-Culture",
  "People-Culture Intern",
  "Project Management",
  "QA Tester",
  "Sales Associate IOI City Mall",
  "Social Media Marketing Specialist",
  "Software Engineering Intern",
  "Supervisor",
  "Treasury",
  "Visual Merchandiser",
  "Account Sales",
  "Brand Ambassador",
  "Business Analyst Intern",
  "Business Development Associate Corporate Sales",
  "Business Development Specialist",
  "Community Associate",
  "Compliance",
  "Customer Support Specialist",
  "Experience Store-Sales Advisor Female",
  "Full Stack Software Developer",
  "Fullstack Developer",
  "Intern Customer Care",
  "Intern Designer Final Semester",
  "Internship for Business Studies-Administration",
  "Internship for Food and Beverage Management",
  "Internship for Sales-Marketing",
  "Internship UI UX Designer",
  "IT Technician",
  "Marketing Communications",
  "Marketing Lead",
  "Merchant Acquisition",
  "Payroll",
  "Restaurant",
  "Retail Merchandiser",
  "Sales-Marketing Executive",
  "Software Programmer",
  "Solution Architect",
  "Store",
  "Trainer",
  "Videographer-Editor",
  "3D Animator",
  "Account",
  "Accounts and Admin",
  "Accounts Executive",
  "Audit Associate",
  "Audit Intern",
  "Business",
  "Business Development Remote",
  "Business Development",
  "Business Development-Sales",
  "Business Development Officer",
  "Content Marketing Specialist",
  "Content Writer Malay-English",
  "Ecommerce Operation",
  "Fashion Designer",
  "Finance Analyst",
  "Full Stack Engineer",
  "HR-Admin Intern",
  "Insurance Advisor-Sales Agent",
  "Intern Human Resources",
  "Intern Sales Admin Final semester",
  "Legal Counsel",
  "Marketing Coordinator",
  "Performance Marketing Associate",
  "Personal to CEO",
  "Product Owner",
  "Property Operations Intern",
  "Purchaser",
  "Retail Sales Associate",
  "Ruby on Rails Developer",
  "Supply Chain Intern",
  "Systems Engineer",
  "UX Generalist",
  "Video Producer",
  "Videographer-Video Editor",
  "Young Business Graduate",
  "3D Artist",
  "Admin Executive",
  "Admin Internships Hybrid",
  "Area Sales",
  "Business Administration Intern",
  "Business Development",
  "Client Relationship",
  "Client Support Specialist",
  "Content Editor",
  "Content Specialist",
  "Corporate Sales Consultant",
  "Customer Happiness Specialist",
  "Customer Service Agent",
  "Customer Service Associate",
  "Customer Service Executive",
  "Digital Content Strategist",
  "Digital Graphic Designer",
  "Driver",
  "Event Sales",
  "Finance Associate",
  "Flutter Developer",
  "Growth Marketer",
  "Head of Sales",
  "HR Business Partner",
  "Intern Logistic",
  "Internship for Business Administration",
  "Internship Motion Designer",
  "Investment Analysis Internship Hybrid",
  "IT Support Engineer",
  "Java Software Developer",
  "Logistic",
  "Maintenance Technician",
  "Marketing and Sales",
  "Multimedia Intern",
  "Office",
  "Performance Marketer",
  "Property Sales",
  "Purchasing Officer",
  "QA",
  "Quality Assurance",
  "Sales Remote",
  "Sales Development",
  "Software QA Engineer",
  "Talent Acquisition Intern",
  "Visual Designer",
  "Wealth Advisor",
  "Web-Graphic Designer",
  "Sales-Marketing",
  "3D Designer",
  "Account Admin",
  "Account Intern",
  "Accounting Internship",
  "Accounts-Finance",
  "Business Development-Marketing",
  "Buyer",
  "Category",
  "Corporate Account",
  "Digital Marketing Lead",
  "Digital Sales Consultant",
  "Ecommerce Internship",
  "Editor",
  "Elite Life Planner",
  "English Teacher",
  "Event Operation",
  "Financial Consultant",
  "General",
  "Group Finance",
  "HR Executive",
  "Human Resource-Admin",
  "Influencer Marketing",
  "Intern Marketing",
  "Intern Telemarketing ",
  "Internship Finance",
  "Internship Graphic Designer",
  "Internship Human Resource",
  "Internship for Finance Students",
  "Internship for Food-Beverage Preparation",
  "Internship for Human Resources",
  "Marketing",
  "Marketing-Sales",
  "Marketing Entrepreneurs Programme Internship",
  "Medical Sales Representative",
  "Motion Graphics Designer",
  "Nurse",
  "Nutritionist",
  "Outlet",
  "PR Consultant",
  "Preschool Teacher",
  "Product Engineer",
  "Production Supervisor",
  "Sales-Business Development",
  "Senior Project Manager",
  "Shipping",
  "Site Supervisor Contract",
  "Social Media-Content Specialist",
  "Social Media Internship",
  "Software Developer DotNet",
  "Software Developer Intern",
  "Technical Lead",
  "Telesales Basic Salary and Commission",
  "Video Editor Cum Videographer",
  "Videographer Cum Editor",
  "Web Programmer",
  "Account and Admin",
  "Account Assistant",
  "Accounts Officer",
  "Admin cum Accounts",
  "Analyst Programmer",
  "Application Consultant",
  "B2B Content Marketing",
  "Barista",
  "Business Development Sales",
  "Business Operations Intern",
  "Campaign",
  "Client Success",
  "Communications",
  "Content Creator Intern",
  "Content Writer Malay And English",
  "Credit Control",
  "CRM",
  "Customer Happiness Associate",
  "Customer Happiness Officer",
  "Data Science Intern",
  "Digital Marketing Consultant",
  "Event Crew",
  "Event Management Intern",
  "Finance-Account",
  "Finance-Accounting Intern",
  "Front End Web Developer",
  "FrontEnd Web Developer",
  "Fund Accountant",
  "Game Developer",
  "Indoor Sales",
  "Intern Property Agent ",
  "Intern Marketing",
  "Internship Human Resources",
  "Internship For Finance",
  "Internship for HR",
  "Internship for Operation ecommerce",
  "Internship for Operation Logistic",
  "Internship Graphic Designer",
  "Internship Project",
  "Internship UI-UX Designer",
  "Investment Analyst Internship",
  "IT Business Analyst",
  "Leasing",
  "Loan Sales Consultant No Cold Calling",
  "Marketing-Communications",
  "Marketing Officer",
  "Office Administrator-Administrative",
  "Partnership",
  "Product Marketing",
  "Quality Assurance Engineer",
  "Sales Intern Arabic Speaker Remote",
  "Software Development Intern",
  "Software Engineer JAVA",
  "Software Engineer-Software Developer",
  "Software Test Engineer",
  "Strategic Partnership",
  "Support Engineer",
  "Technical Sales Engineer",
  "Technical Support Specialist",
  "Video Editor cum Graphic Designer",
  "video editor intern",
  "Warehouse Supervisor",
  "Web Designer Internship",
  "Young Business Graduate Remote",
  "HR",
  "Account Fresh Graduate",
  "Admin-Accounts",
  "Admin-HR",
  "Admin Officer",
  "Admin-Secretarial Internship Hybrid",
  "Affiliate Marketing",
  "Beauty Therapist",
  "Brand Communications Intern",
  "Business Consultant-Sales",
  "Business Development Mandarin Speaker",
  "Business Management Intern",
  "Cashier",
  "Client Service",
  "Company Secretarial",
  "Consultant",
  "Content Marketing Writer",
  "Creative Content Writer",
  "Creative Graphic Designer",
  "Customer Service Consultant",
  "Customer Success Representative",
  "Digital Marketing Associate",
  "Digital Strategist",
  "Ecommerce Operations",
  "Ecommerce Sales",
  "Email Marketing Specialist",
  "Event Management-Operation Intern Hybrid",
  "Event Sales-Marketing",
  "Finance-Administration",
  "Finance and Administration",
  "Finance Executive",
  "Finance-Accounting Internship Hybrid",
  "Financial Controller",
  "General Admin",
  "General Clerk",
  "Graphic-Multimedia Designer",
  "Graphic Designer cum Marketing",
  "Interior Design",
  "Interior Design Intern",
  "Intern Product Development Food Science",
  "Intern Graphic Designer",
  "Internship Business Development",
  "Internship Business-Marketing",
  "Internship Marketing",
  "Internship For Graphic Designer",
  "Internship in Graphic Design",
  "Internship Programme",
  "IT Administrator",
  "IT Analyst",
  "IT Recruiter",
  "JAVA Programmer",
  "Lead Generation Specialist Social Media",
  "Learning-Development",
  "Legal Intern",
  "Marketing Manager",
  "Media",
  "Multimedia Graphic Designer",
  "PHP Laravel Developer",
  "PHP Software Developer",
  "Process Engineer",
  "Procurement Intern",
  "Procurement Officer",
  "Product Analyst",
  "Production Engineer",
  "Production Intern",
  "Property Management Intern",
  "Sales-Sales",
  "Sales-Business Development",
  "Sales-Business Developement",
  "Sales Interior Designer",
  "Sales Marketing",
  "Sales Operations",
  "Senior Event Manager",
  "Service Technician",
  "Social Media Strategist",
  "Software Engineer Backend",
  "Software Quality Assurance Engineer",
  "Staff Nurse",
  "Startup Accelerator Internship",
  "Storekeeper",
  "Technical Consultant",
  "Test",
  "Training",
  "UI-UX Developer",
  "Videographer Intern",
  "Warehouse Admin",
  "Wealth Management Advisor",
  "Account Clerk",
  "Account Management",
  "Account Payable",
  "Accounting-Finance",
  "Accounting Intern Hybrid",
  "Accounts Assistant",
  "Accounts Intern",
  "Accounts Payable",
  "Administrative Clerk",
  "Advertising Sales Account",
  "Application Support Engineer",
  "Associate Product",
  "Automation Engineer",
  "Backend Engineer",
  "Brand Ambassador ",
  "Business Sales Ambassador",
  "Client Relations",
  "Clients Sales Representative",
  "Commercial",
  "Corporate Projects Intern",
  "Creative-Graphic Designer",
  "Credit Analyst",
  "Customer Relationship",
  "Customer Support Representative",
  "Data Analyst Intern",
  "Digital",
  "Digital Content Creation Intern",
  "Digital Performance",
  "Engineer",
  "Entrepreneurial-Management Internship Hybrid",
  "Finance-Credit Product Intern",
  "Full Stack Software Engineer",
  "Growth Marketing Associate",
  "Head of Operations",
  "HR Recruiter",
  "Human Resources Intern Hybrid",
  "Implementation and Support lead",
  "Intern Graphic Designer",
  "Intern Customer Relations",
  "Internship Economic-Finance-Banking Student",
  "Internship Marketing-Business Development",
  "Internship Finance",
  "Internship for IT Students",
  "Internship Human Resources",
  "Inventory",
  "IT Support Specialist",
  "Management Intern",
  "Marketing Operations",
  "Marketing Communication-Admin Intern Hybrid",
  "Marketing-PR Internship Hybrid",
  "Media Sales Strategist",
  "Operation Admin",
  "Operation Intern",
  "Outdoor Sales",
  "People and Culture Intern",
  "Personal cum Admin",
  "Project Designer",
  "Quality Assurance Analyst",
  "Quantitative Analyst",
  "Sales-Marketing Internship",
  "Sales and Marketing Specialist",
  "Sales Lead",
  "Sales Merchandiser",
  "Sales Ninja",
  "Search Engine Optimizationn SEO Specialist",
  "Shipping Coordinator",
  "Site",
  "Social Media Designer",
  "Software Developer PHP",
  "Software Engineer Full Stack",
  "Special Project Intern",
  "Startup Accelerator Internship Hybrid",
  "Talent Acquisition-Recruitment",
  "Tech Lead",
  "Telesales Representative",
  "Unit",
  "Video Editor Internship",
  "Video Production",
  "Videographer-Video Editor Intern",
  "Internal Audit",
  "2D Animator",
  "Account-Account",
  "Account Servicing",
  "Accounts",
  "Administrative Officer",
  "Advertising Account",
  "Associate Creative Director",
  "Brand Communications",
  "Business Administrative Intern",
  "Business Development Representative",
  "Business Operations",
  "Business Relationship",
  "Channel Sales",
  "Chemist",
  "Copy Writer",
  "Copywriter English",
  "Copywriting Intern",
  "Corporate Projects Consultant",
  "Creative Design Intern",
  "Creative Writer",
  "Customer Service Internship",
  "Customer Support Taiwan Market",
  "Customer Support Associate",
  "Data Entry",
  "Design Consultant",
  "Draughtsman",
  "E Commerce",
  "ECommerce Associate",
  "Education Consultant",
  "Email Marketing Specialist EDM",
  "Engineering",
  "English CopyWriter",
  "Equity Analyst",
  "Event-Marketing",
  "Finance-Accounting",
  "Finance internship with a Fintech startup",
  "Front Desk Assistant",
  "Front End Engineer",
  "Full Stack Software Developer Angular NodeJS",
  "General Worker",
  "Graphic-Multimedia Designer",
  "Graphic Design Internship Hybrid",
  "Growth Hacker",
  "Head of Business Development",
  "HR manager",
  "HR Specialist",
  "Human Resource Generalist",
  "Implementation Specialist",
  "Influencer Marketing Internship",
  "Inside Sales Account",
  "Inside Sales Coordinator",
  "Insurance-Financial Advisor Sales Agent",
  "Insurance Advisor",
  "Intern Finance cum Admin Last Semester",
  "Intern Warehouse",
  "Internship 3D Art",
  "Internship Business-Marketing-Management Student",
  "Internship Digital Design",
  "Internship Entrepreneur Program",
  "Internship for Accounting Student",
  "Internship for Business Students",
  "Internship for Content Creators",
  "Internship for Engineering Students",
  "Internship for Human Resource Management Students",
  "Internship for Logistic and Transportation Student",
  "Internship of Video Editor",
  "Internship Opportunities at BloomThis",
  "Internships",
  "IT Consultant",
  "IT Programmer",
  "IT Technical Support",
  "Maintenance Specialist",
  "Marketing-Head of Marketing",
  "Marketing Strategist",
  "Mechanical Engineer",
  "Mobile Application Developer Android",
  "Motion Graphic Artist",
  "Multimedia Design Intern",
  "Multimedia Designer Internship",
  "Online Content Editor",
  "Payroll Specialist",
  "Photographer cum Videographer",
  "Production Planning",
  "Program Coordinator Entrepreneur-Startup Program",
  "Project Coordinator eCom Fulfillment",
  "Project Management Internship Hybrid",
  "Project Sales",
  "Property Advisor",
  "Property Agent-Real Estate Negotiator",
  "Real Estate Intern",
  "RPA Developer",
  "Sale",
  "Sales-Sales",
  "Sales And Marketing Internship",
  "Sales Associates",
  "Sales Manager",
  "Sales",
  "Sales Specialist BIS Production Solutions",
  "Senior Content Writer",
  "Service Crew",
  "Shipping-Logistics",
  "Social Media Content Writer",
  "Social media copywriter",
  "Software Developer Full Stack",
  "Software Engineer Full Stack",
  "Software Project",
  "Software Tester Internship",
  "Store Keeper",
  "System Support Engineer",
  "Team Leader",
  "Technical Project",
  "Test Engineer",
  "Transport Planner-Customer Service Associate",
  "Video Content Creator",
  "Videographer-Video Editor",
  "Videographer-Video Editor Intern",
  "Visual Merchandising",
  "Warehouse Administrative Associate",
  "Warehouse Operator",
  "Web Development Intern",
  "Web Project",
  "Customer Care Team",
  "Customer Service",
  "Growth Planning Campaign",
  "Marketing Branding-Collaboration",
  "Sales Plant Origins Female",
  "Graphic Designer",
  "Brand Communications",
  "GOBS HR Intern Vacancy",
  "3D Generalist",
  "Account Leasing",
  "Account Mandarin Speaking",
  "Account-Finance",
  "Account Cum HR",
  "Account Officer",
  "Accounting-Finance Intern",
  "Accounts Receivable Analyst",
  "Admin-Account",
  "Admin-Operations",
  "Advertising Operations",
  "Ambassador",
  "Android Application Developer",
  "Angular Developer",
  "Assistant Strategic Partnership",
  "Awesome Graphic Designer",
  "Backend Developer Nodejs",
  "Beauty Advisor",
  "Branding",
  "Business Admin Intern",
  "Business Admin Internship",
  "Business Developement",
  "Business Development Manager",
  "Clerk",
  "Client",
  "Client Account",
  "Clients Marketing Ambassador",
  "Community Specialist",
  "Content Creator Internship",
  "Content Marketer",
  "Corporate Sales Intern",
  "Creative Lead",
  "Creative Marketing Intern",
  "Customer Care Consultant",
  "Customer Happiness-Operations eCom Fulfillment",
  "Customer Relationship Intern",
  "Customer Service Administrator",
  "Customer Services",
  "Developer Intern",
  "Digital Content Writer Intern",
  "Digital Marketing Specialist Google-FB Ads",
  "Digital Performance Marketing",
  "Digital-Graphic Designer",
  "Dot Net Developer",
  "DTP Artist",
  "Economist",
  "Engineering Intern",
  "Export International Business",
  "Export Sales",
  "Food and Beverage Operation",
  "Fashion Merchandiser",
  "Field Sales",
  "Finance Admin",
  "Firmware Engineer",
  "Freelance Graphic Designer",
  "Front Desk",
  "Front end developer HTML5-CSS",
  "Front Office",
  "General Internship",
  "Graphic and Multimedia Designer",
  "Graphic Design",
  "Graphic Designer Can Work Remotely",
  "Group Accountant",
  "HR Officer",
  "HR-Admin",
  "Human Resource Cum Admin",
  "Human Resource Internship Remote",
  "Human Resources-Admin Internship",
  "Implementation Consultant",
  "Information Technology Intern",
  "Interior Designer KL",
  "Intern Sales-Marketing",
  "Intern-Full Time Retail Operations",
  "Internship Finance-Accounting",
  "Internship Graphic Design",
  "Internship HR",
  "Internship IT",
  "Internship for Account",
  "Internship for Business Studies",
  "Internship for ECommerce",
  "Internship for IT",
  "Internship for Logistics and Business Students",
  "Internship for Marketing Students",
  "Internship for Software Developer Flutter-Laravel",
  "Internship for Web Developer",
  "Internship Marketing",
  "IT Admin",
  "Key Account-Retail",
  "Key Accounts Sales ",
  "Management Trainee Internship Hybrid",
  "Marketer",
  "Marketing-Communications Intern",
  "Marketing-Sales Representative",
  "Marketing Representative",
  "Marketing Trainee",
  "Media Buyer",
  "Media Development Internship",
  "Merchandising Intern",
  "MIS",
  "Office Admin-Admin",
  "Office Intern",
  "Operation Officer",
  "Operations Internship",
  "Partnership Marketing",
  "Patent Engineer",
  "Personal Immediate starter",
  "Personal to Managing Director",
  "Photography Intern",
  "Planning",
  "Pre Sales Technician-Engineer",
  "Product Intern",
  "Product Management Intern",
  "Product Research and Development Intern",
  "Product Trainer",
  "Production Internship",
  "Program",
  "Project Marketing-Advertising",
  "Project Admin",
  "Project Executive",
  "Property Agent-Real Estate Negotiator",
  "Property Consultant",
  "Python Developer",
  "Quality Assurance QA Tester",
  "Quality Control",
  "Quality Engineer",
  "Research and Development",
  "ReactJS Developer",
  "Real Estate Negotiator-Property Agent",
  "Regional Marketing",
  "Regional Sales",
  "Research-Content Development Intern",
  "Retail Operations",
  "Retail Sales Consultant",
  "Retail Store",
  "Safety Officer",
  "Sales Mandarin Speaking",
  "Sales",
  "Sales-Marketing Associate",
  "Sales-Marketing Representative",
  "Sales Advisor Plant Origins Female Only",
  "Sales Internship",
  "Sales Lead Generator",
  "Sales Operation",
  "Sales Personnel",
  "Sales Promoter",
  "Sales Support Specialist",
  "Sales Team Leader",
  "Scrum Master",
  "Secretary cum Admin",
  "Senior Account Executive",
  "Senior HR Executive",
  "Social Media-Community",
  "Social Media Account",
  "Social Media Community",
  "Software Architect",
  "Software Developer Web",
  "Solar Sales Consultant",
  "Strategy Planner",
  "Tech Support",
  "Technical Sales",
  "Telemarketing Executive",
  "Telesales Consultant",
  "Telesales Officer",
  "Test Automation Engineer",
  "to Director",
  "UI-UX Designer",
  "UI-UX Intern",
  "Video Content Production Intern",
  "Web Designer-Developer",
  "WordPress Administrator",
  "IT Support",
  "People-Culture",
  "Hub Delivery Operations",
  "Sales",
  "Sales Vending Machine",
  "DotNet Software Engineer",
  "PHP Developer",
  "3D Visualiser",
  "3D Visualizer",
  "Account Mandarin Speaker",
  "Account-Account",
  "Account Government-Corporate Penang",
  "Account GovernmentLinked Corporation",
  "Account",
  "Account Internship",
  "Accounts BIS Large Enterprise Group",
  "Accounts AP-AR",
  "Accounts Admin",
  "Accounts Analyst",
  "Accounts Receivable",
  "Admin Intern",
  "Admin-Data Entry",
  "Admin-Accounting Internship",
  "Admin cum HR",
  "Admin-HR",
  "Administrative HR",
  "Administrative Coordinator",
  "Application Support Analyst",
  "Application Support Specialist",
  "Arvato Systems Malaysia Internship Program AIP",
  "Banking-Finance Internship",
  "Beauty Consultant",
  "Billing",
  "Business Development Penang",
  "Business Development",
  "Business Development Sales",
  "Business Development Intern Healthcare Solutions",
  "Business Development Intern with a Fintech",
  "Business Intelligence",
  "Business Operation",
  "Business Partner",
  "Campaign Specialist",
  "Client Relations Specialist",
  "Cluster Marketing",
  "Compliance Officer",
  "Content Copywriter",
  "Content Lead",
  "Content Writer-Editor",
  "Corporate FInance",
  "Corporate Insights Analyst Intern",
  "Corporate Sales Specialist",
  "Corporate Sales Account Johor Bharu",
  "Creative Designer Internship",
  "Creative Designer-Art Director",
  "Creative Internship",
  "Customer Onboarding Specialist",
  "Customer Service Mandarin Speaker",
  "Customer Service Mandarin Speaker",
  "Customer Service cum Admin",
  "Customer Service Excellent",
  "Customer Success Associate",
  "Design Engineer",
  "Digital Account Client Servicing",
  "Digital Account Intern",
  "Digital Art Director",
  "Digital Campaign",
  "Digital Client Servicing",
  "Digital Content Marketing",
  "Digital Marketing ",
  "Digital Marketing Analyst",
  "Digital Marketing Operations",
  "Digital Media",
  "Digital Media Specialist",
  "ECommerce Internship",
  "ECommerce Executive",
  "eCommerce Marketplace",
  "ECommerce Multimedia Design Internship",
  "Education Counsellor",
  "Education Counsellor-Recruitment Officer",
  "Education Online Work From Home Business Freelance",
  "Engineering Internship",
  "Event Sales and Marketing Ambassador Fresh grad",
  "Events Intern",
  "Export",
  "Field Service Engineer",
  "Finance Manager",
  "Finance Operation Internship",
  "Finance Operations Internship",
  "Front Desk-Space",
  "Furniture Designer",
  "Game Designer",
  "Graduate Trainee Full Stack Software Developer",
  "Graphic Artist",
  "Graphic Designer Intern",
  "Graphic Designer-Content Creator",
  "Graphic Designer-Art Director",
  "Graphic Designer cum Video Editor",
  "Graphic Designers",
  "Head of Digital Marketing",
  "Head of Finance",
  "HR-Finance",
  "HRBP",
  "Human Capital",
  "Human Resource Associate",
  "Human Resource Executive",
  "Human Resource Generalist Individual Contributor",
  "Human Resource Recruiter",
  "Information Technology Internship",
  "Intern Compliance",
  "Intern Finance cum Admin",
  "Intern of Joy",
  "Intern Web Developer",
  "Intern Admin",
  "Interns",
  "Internship Media-Graphic-Art Students",
  "Internship Product Marketing",
  "Internship Social Media",
  "Internship for Content Creation",
  "Internship for Content Creator",
  "Internship for Copywriting Marketing",
  "Internship for Creative Marketing",
  "Internship for Marketing-Digital Marketing",
  "Internship for Sales and Marketing",
  "Internship Graphic Design",
  "Internship in Digital Marketing",
  "Internship of Graphic Designer",
  "Internship UX Researcher",
  "Internship Partner Development",
  "Internship Quality Assurance",
  "InternshipBusiness DevelopmentEvent Management",
  "Investment Analyst",
  "iOS Mobile Application Developer",
  "iOS Mobile Developer",
  "IT Sales",
  "Key Account Penang",
  "Lead Engineer",
  "Learning-Development Intern",
  "Management Internship",
  "Management Trainee KL-Selangor",
  "Marketing Ecommerce",
  "Marketing Internship",
  "Marketing-PR",
  "Marketing",
  "Marketing Administrator",
  "Marketing Campaign",
  "Marketing Communication",
  "Marketing Consultant",
  "Marketing Design Intern",
  "Marketing Designer",
  "Marketing Intern Hybrid",
  "Marketing Interns",
  "Mechanical Design Engineer",
  "Medical Representative",
  "Medical Sales",
  "Merchant Operations",
  "Merchant Success",
  "Motion Designer",
  "Motion Graphic Designer Intern",
  "Multimedia-Graphic Designer",
  "Multimedia Design Internship",
  "Multiple Positions Available Klang Valley",
  "Occupational Therapist",
  "Online Sales",
  "Operation Support",
  "Operations Penang Branch",
  "Operations-Analytics",
  "Operations Coordinator",
  "Outlet Supervisor",
  "Part Time Retail Stylist",
  "Partner Development",
  "Payroll Analyst",
  "People-Culture Associate Entry Level",
  "Performance Marketing Intern",
  "Performance Marketing Lead",
  "Pharmacist",
  "Pharmacy Intern",
  "PHP Software Engineer",
  "Physiotherapist",
  "Plant Engineer",
  "Plant Origins Startup Team",
  "PR Intern",
  "PreSales Engineer",
  "Principal",
  "Product Sales Specialist",
  "Project Administrator",
  "Project and Marketing Specialist",
  "Project Lead",
  "Property Agent-Real Estate Negotiator",
  "Pruventure",
  "Public Relation Intern",
  "Public Relations Intern",
  "Purchasing Executive",
  "QA-QC",
  "Real Estate",
  "Real Estate Negotiator-Real Estate Entrepreneur",
  "Recruitment Intern",
  "Recruitment Officer",
  "Reporting Analyst",
  "Research",
  "Research Analyst",
  "Restaurant Supervisor",
  "Retail Management Trainee",
  "Sale and Marketing",
  "Sales Outdoor",
  "Sales-Account",
  "Sales-Sales Admin",
  "Sales-Application Engineer",
  "Sales-Events Representative",
  "Sales and Marketing Leader",
  "Sales and Marketing Specialist Coaching Provided",
  "Sales and Marketing SpecialistFresh Grad Welcome",
  "Sales Consultant Digital Marketing",
  "Sales Coordinator Mandarin Speaker",
  "Sales Development Representative Remote",
  "Sales Officer",
  "Sales Supervisor",
  "Sales Support Coordinator",
  "Sales Support Officer",
  "Sales Team",
  "Salesperson",
  "Search Engine Optimization Associate",
  "SEO Intern",
  "Service Desk Analyst",
  "Site Engineer",
  "Social Media-Digital Marketing Specialist",
  "Social Media and Digital Marketing Intern",
  "Social Media and Digital Marketing Specialist",
  "Social Media Associate",
  "Social Media Executive",
  "Software Application Developer",
  "Software Development Engineer",
  "Software Engineer-Software Developer",
  "Software Engineers",
  "Software Quality Assurance",
  "Software Support Engineer",
  "Strategic Business Development",
  "Super Intern",
  "Supply Chain Associate",
  "System Developer",
  "Talent",
  "Talent-Culture Specialist",
  "Talent Recruitment-Human Resource",
  "Tax Intern",
  "Tech Support Intern",
  "Technical Engineer",
  "Technical SEO Specialist",
  "Technical Support Singapore Market",
  "Trainee",
  "Translator",
  "UI-UX Design Intern",
  "UI-UX Designer Internship",
  "User Interface UI Designer",
  "Videographer-Editor",
  "Videographer-Photographer",
  "Waiter-Waitress",
  "Warehouse Storekeeper",
  "Wealth Management",
  "Web Application Developers",
  "Web Developer Wordpress",
  "Web Developer-Programmer",
  "Webmaster",
  "Webmaster Penang",
  "Webmaster Penang-KL",
  "Website Developer",
  "Young Graduates",
];

export const SWITCH_COUNTRY_FLAG_KEYNAME = "SHOULD_SWITCH_COUNTRY";

export const STOP_SYNC_LOGIN = "STOP_SYNC_LOGIN";

export const SHOW_CHAT = "show-chat";
