/*
 * This is a special reducer and basically it does not have type and action
 * It is used for axios cancelToken management
 */
const initialState = {
  cancelTokens: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};
