import {
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Fragment } from "react";
import Color from "../../../assets/colors";
import * as config from "../../../config/config";
import { months, profileOverviewSections } from "../../../helpers/constant";
import { sortWorkingExperiences } from "../../../helpers/data_management";

const interTtf = "https://assets.hiredly.com/fonts/Inter-Regular.ttf";
const interboldTtf = "https://assets.hiredly.com/fonts/Inter-Bold.ttf";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: interTtf,
    },
    {
      src: interboldTtf,
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    fontFamily: "Inter",
    padding: 48,
  },
  image: {
    marginLeft: 0,
    marginRight: 32,
    width: 80,
    height: 80,
    borderRadius: "50%",
  },
  profileBasicInfo: {
    flexDirection: "row",
    alignItems: "center",
    height: 80,
    marginBottom: "32px",
    justifyContent: "space-between",
  },
  profileNameContainer: {
    //
  },
  title: {
    fontFamily: "Inter",
    fontWeight: "bold",
    fontSize: "25px",
    color: Color.hiredlyPurple,
  },
  positionTitle: {
    fontSize: "16px",
  },
  profileBasicInfoContact: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
  },
  contactDetails: {
    fontFamily: "Inter",
    fontSize: 10,
    fontWeight: "normal",
  },
  contactDetailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 10,
    width: 120,
  },
  lastContactDetailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 120,
  },
  contactDetailIcon: {
    width: 10,
    height: 10,
    marginRight: 10,
  },
  greyAndSmall: {
    fontSize: 12,
    color: "#00000060",
    lineHeight: "1.5px",
  },
  sectionTitle: {
    fontFamily: "Inter",
    fontWeight: "bold",
    fontSize: 14,
    color: Color.hiredlyPurple,
    paddingBottom: 10,
    borderBottom: `1px solid ${Color.grey}`,
    marginBottom: 10,
  },
  description: {
    fontSize: 12,
  },
  experienceLocation: {
    marginVertical: 4,
    fontSize: 12,
    color: "#00000060",
  },
  experienceContainer: {
    marginBottom: 16,
  },
  rowAndSpaceBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "top",
  },
  experienceDesc: {
    fontSize: 12,
    lineHeight: 1.3,
  },
  experienceTitle: {
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: 1.3,
  },
  languageRowSpaceBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  flexColumn: {
    flexDirection: "column",
  },
  flexRow: {
    flexDirection: "row",
  },
  sectionContainer: {
    marginBottom: 24,
  },
  skillsSectionContainer: {
    marginBottom: 24,
  },
  skillsContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  skillItem: {
    width: "50%",
    paddingRight: 5,
    lineHeight: 1.3,
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
  },
  nameAndTitleContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: "240px",
  },
  flexRowAndWrapAndFixWidth: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "380px",
  },
  flexRowAndWrapAndFixWidthL: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "420px",
    marginBottom: "4px",
  },
  flexRowAndWrap: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "4px",
  },
});

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

export const trimTrailingChars = (s) => {
  if (s === null || s === undefined) {
    return "";
  }
  let firstAlphabetIndex = s.search(/[a-zA-Z]/);

  if (firstAlphabetIndex == -1) {
    return "";
  }

  let firstNonAlphabetBeforeAlphabet =
    s.slice(0, firstAlphabetIndex).match(/[^a-zA-Z]/) ?? "";

  let newString =
    (firstNonAlphabetBeforeAlphabet
      ? firstNonAlphabetBeforeAlphabet + " "
      : "") + s.slice(firstAlphabetIndex).replace(/[^a-zA-Z]+$/g, "");

  return newString;
};

function ResumePdf(props) {
  const { currentUser, sectionSequence } = props;

  const secSeqFallback = sectionSequence ?? profileOverviewSections;

  const {
    workingExperiences,
    freshGrad,
    educations,
    projectAndPortfolios,
    certificateAndTrainings,
    honorAndAwards,
    extracurriculars,
    languages,
    skills,
  } = currentUser || {};

  const contactIcon = config.assetDomain + "/images/resume-contact-icon.png";
  const portfolioIcon =
    config.assetDomain + "/images/resume-portfolio-icon.png";
  const mailIcon = config.assetDomain + "/images/resume-mail-icon.png";

  const yearDescendWorkExp = workingExperiences?.nodes
    ? sortWorkingExperiences(workingExperiences?.nodes)
    : [];

  const yearDescendEducations = educations?.nodes?.sort(
    (a, b) =>
      a.graduationYear &&
      b.graduationYear &&
      b.graduationYear - a.graduationYear
  );

  const yearDescendProjects = projectAndPortfolios?.nodes?.sort(
    (a, b) =>
      a.completionYear &&
      b.completionYear &&
      b.completionYear - a.completionYear
  );

  const yearDescendCert = certificateAndTrainings?.nodes?.sort(
    (a, b) =>
      a.completionYear &&
      b.completionYear &&
      b.completionYear - a.completionYear
  );

  const yearDescendHonourAwards = honorAndAwards?.nodes?.sort(
    (a, b) =>
      a.completionYear &&
      b.completionYear &&
      b.completionYear - a.completionYear
  );

  const yearDescendExtracurricular = extracurriculars?.nodes?.sort(
    (a, b) =>
      a.completionYear &&
      b.completionYear &&
      b.completionYear - a.completionYear
  );

  /**
   * For the text below username.
   *
   * Idea is regardless of whether fresh grad anot,
   * as long as there is work exp, take the latest work exp job title
   * ELSE
   * take latest educational institutation name
   */
  const basicInfoJobTitle =
    yearDescendWorkExp?.length > 0
      ? yearDescendWorkExp[0]?.jobTitle
      : freshGrad
      ? yearDescendEducations[0]?.educationalInstitution
      : null;

  const handleSectionSequence = (obj) => {
    const { id, sectionName } = obj;
    switch (sectionName) {
      case "Work Experience":
        return (
          workingExperiences?.totalCount > 0 && (
            <View style={styles.sectionContainer}>
              {yearDescendWorkExp?.map((workExp, index) => {
                const concatenatedDesc = workExp.description;
                const removeTrailingBullet = trimTrailingChars(
                  concatenatedDesc?.trim()
                );
                const splitDescArr =
                  removeTrailingBullet?.split("\n").filter((val) => val)
                    .length > 0
                    ? removeTrailingBullet?.split("\n")
                    : [];
                const startMonthInStr =
                  months.find((month) => month.id === workExp?.startDateMonth)
                    ?.label ?? "";

                const endMonthInStr =
                  workExp?.endDateMonth === null
                    ? "Present"
                    : months.find((month) => month.id === workExp?.endDateMonth)
                        .label;

                const endYearInStr =
                  workExp?.endDateYear === null ? "" : workExp?.endDateYear;

                return (
                  <View wrap>
                    {index === 0 && (
                      <Text style={styles.sectionTitle}>Work Experience</Text>
                    )}
                    <View
                      style={
                        index !== workingExperiences?.totalCount - 1
                          ? styles.experienceContainer
                          : undefined
                      }
                    >
                      <View style={styles.rowAndSpaceBetween}>
                        <View style={styles.flexRowAndWrapAndFixWidth}>
                          {workExp.jobTitle &&
                          typeof workExp.jobTitle === "string" &&
                          workExp.jobTitle.length > 0
                            ? workExp?.jobTitle?.split(" ").map((word) => {
                                return (
                                  <Text style={styles.experienceTitle}>
                                    {word}{" "}
                                  </Text>
                                );
                              })
                            : "CandidateWorkExperienceTitle"}
                        </View>

                        <Text style={styles.greyAndSmall}>
                          {startMonthInStr ||
                          workExp.startDateYear ||
                          (endMonthInStr && workExp.endDateYear)
                            ? `${startMonthInStr} ${workExp.startDateYear} - ${endMonthInStr} ${endYearInStr}`
                            : `-`}
                        </Text>
                      </View>
                      <Text style={styles.greyAndSmall}>
                        {workExp.companyName ??
                          "CandidateWorkExperienceCompany"}
                      </Text>

                      {(splitDescArr !== null || splitDescArr !== undefined) &&
                        Array.isArray(splitDescArr) &&
                        splitDescArr.length > 0 &&
                        splitDescArr?.map((desc) => (
                          <View style={styles.flexRowAndWrap}>
                            {desc.split(" ").map((word, wordIndex) => (
                              <Text style={styles.experienceDesc}>
                                {word.trim()}{" "}
                              </Text>
                            ))}
                          </View>
                        ))}
                    </View>
                  </View>
                );
              })}
            </View>
          )
        );

      case "Education":
        return (
          educations?.totalCount > 0 && (
            <View style={styles.sectionContainer}>
              {yearDescendEducations?.map((education, index) => {
                const concatenatedDesc = education.description;
                const removeTrailingBullet = trimTrailingChars(
                  concatenatedDesc?.trim()
                );
                const splitDescArr =
                  removeTrailingBullet?.split("\n").filter((val) => val)
                    .length > 0
                    ? removeTrailingBullet?.split("\n")
                    : [];

                return (
                  <View wrap={false}>
                    {index === 0 && (
                      <Text style={styles.sectionTitle}>Education</Text>
                    )}
                    <View
                      style={
                        index !== educations?.totalCount - 1
                          ? styles.experienceContainer
                          : undefined
                      }
                    >
                      <View style={styles.rowAndSpaceBetween}>
                        <Text style={styles.experienceTitle}>
                          {education.educationLevel ??
                            "CandidateEducationLevel"}
                        </Text>
                        <Text style={styles.greyAndSmall}>
                          {education.graduationYear ??
                            "CandidateGraduationYear"}
                        </Text>
                      </View>
                      <View style={styles.flexRowAndWrapAndFixWidth}>
                        <Text style={styles.greyAndSmall}>
                          {education.educationalInstitution ??
                            "CandidateEducationalInstitution"}
                        </Text>
                      </View>
                      {/* {splitDescArr.map((desc) => (
                        <Text style={styles.experienceDesc}>{desc.trim()}</Text>
                      ))} */}
                      {splitDescArr &&
                        Array.isArray(splitDescArr) &&
                        splitDescArr.length > 0 &&
                        splitDescArr?.map((desc) => (
                          <View style={styles.flexRowAndWrap}>
                            {desc.split(" ").map((word, wordIndex) => (
                              <Text style={styles.experienceDesc}>
                                {word.trim()}{" "}
                              </Text>
                            ))}
                          </View>
                        ))}
                    </View>
                  </View>
                );
              })}
            </View>
          )
        );

      case "Skills":
        return (
          skills?.length > 0 && (
            <View style={styles.skillsSectionContainer} wrap={false}>
              <Text style={styles.sectionTitle}>Skills</Text>
              <View style={styles.skillsContainer}>
                {skills?.map((skill, index) => {
                  return (
                    <View style={styles.skillItem}>
                      <Text style={styles.experienceDesc}>
                        {skill.name ?? "CandidateSkillName"}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
          )
        );

      case "Language":
        return (
          languages?.length > 0 && (
            <View style={styles.sectionContainer}>
              {languages?.map((lang, index) => {
                return (
                  <View wrap={false}>
                    {index === 0 && (
                      <Text style={styles.sectionTitle}>Languages</Text>
                    )}
                    <View
                      style={
                        index !== languages?.length - 1
                          ? styles.languageRowSpaceBetween
                          : styles.rowAndSpaceBetween
                      }
                    >
                      <Text style={styles.experienceTitle}>
                        {lang.language ?? "CandidateLanguage"}
                      </Text>
                      <Text style={styles.greyAndSmall}>
                        {lang.proficiency_level ??
                          "CandidateLanguageProficiency"}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          )
        );

      case "Projects":
        return (
          projectAndPortfolios?.totalCount > 0 && (
            <View style={styles.sectionContainer}>
              {yearDescendProjects?.map((project, index) => {
                const concatenatedDesc = project.description;
                const removeTrailingBullet = trimTrailingChars(
                  concatenatedDesc?.trim()
                );
                const splitDescArr =
                  removeTrailingBullet?.split("\n").filter((val) => val)
                    .length > 0
                    ? removeTrailingBullet?.split("\n")
                    : [];
                return (
                  <View wrap={false}>
                    {index === 0 && (
                      <Text style={styles.sectionTitle}>Projects</Text>
                    )}
                    <View
                      style={
                        index !== projectAndPortfolios?.totalCount - 1
                          ? styles.experienceContainer
                          : undefined
                      }
                    >
                      <View style={styles.rowAndSpaceBetween}>
                        <View style={styles.flexRowAndWrapAndFixWidthL}>
                          <Text style={styles.experienceTitle}>
                            {project.title ?? "CandidateProjectTitle"}
                          </Text>
                        </View>
                        <Text style={styles.greyAndSmall}>
                          {project.completionYear ??
                            "CandidateProjectCompletionYear"}
                        </Text>
                      </View>
                      <Text style={styles.greyAndSmall}>
                        {project.projectUrl}
                      </Text>
                      {splitDescArr &&
                        Array.isArray(splitDescArr) &&
                        splitDescArr.length > 0 &&
                        splitDescArr?.map((desc) => (
                          <View style={styles.flexRowAndWrap}>
                            {desc.split(" ").map((word, wordIndex) => (
                              <Text style={styles.experienceDesc}>
                                {word.trim()}{" "}
                              </Text>
                            ))}
                          </View>
                        ))}
                    </View>
                  </View>
                );
              })}
            </View>
          )
        );

      case "Certificates and Training":
        return (
          certificateAndTrainings?.totalCount > 0 && (
            <View style={styles.sectionContainer}>
              {yearDescendCert?.map((certAndTraining, index) => {
                return (
                  <View wrap={false}>
                    {index === 0 && (
                      <Text style={styles.sectionTitle}>
                        Certificates & Training
                      </Text>
                    )}
                    <View
                      style={
                        index !== certificateAndTrainings?.totalCount - 1
                          ? styles.experienceContainer
                          : undefined
                      }
                    >
                      <View style={styles.rowAndSpaceBetween}>
                        <Text style={styles.experienceTitle}>
                          {certAndTraining.title ?? "CandidateCertificateName"}
                        </Text>
                        <Text style={styles.greyAndSmall}>
                          {certAndTraining.completionYear ??
                            "CandidateCertificateCompletionYear"}
                        </Text>
                      </View>
                      <Text style={styles.greyAndSmall}>
                        {certAndTraining.issuingOrganization ??
                          "CandidateCertificateIssuingOrganisation"}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          )
        );

      case "Honours and Awards":
        return (
          honorAndAwards?.totalCount > 0 && (
            <View style={styles.sectionContainer}>
              {yearDescendHonourAwards?.map((honorAward, index) => {
                const concatenatedDesc = honorAward.description;
                const removeTrailingBullet = trimTrailingChars(
                  concatenatedDesc?.trim()
                );
                const splitDescArr =
                  removeTrailingBullet?.split("\n").filter((val) => val)
                    .length > 0
                    ? removeTrailingBullet?.split("\n")
                    : [];
                return (
                  <View wrap={false}>
                    {index === 0 && (
                      <Text style={styles.sectionTitle}>Honours & Awards</Text>
                    )}
                    <View
                      style={
                        index !== honorAndAwards?.totalCount - 1
                          ? styles.experienceContainer
                          : undefined
                      }
                    >
                      <View style={styles.rowAndSpaceBetween}>
                        <View style={styles.flexRowAndWrapAndFixWidthL}>
                          <Text style={styles.experienceTitle}>
                            {honorAward.title ?? "CandidateHonourAndAwardTitle"}
                          </Text>
                        </View>
                        <Text style={styles.greyAndSmall}>
                          {honorAward.completionYear ??
                            "CandidateHonourAndAwardCompletionYear"}
                        </Text>
                      </View>
                      {splitDescArr &&
                        Array.isArray(splitDescArr) &&
                        splitDescArr.length > 0 &&
                        splitDescArr?.map((desc) => (
                          <View style={styles.flexRowAndWrap}>
                            {desc.split(" ").map((word, wordIndex) => (
                              <Text style={styles.experienceDesc}>
                                {word.trim()}{" "}
                              </Text>
                            ))}
                          </View>
                        ))}
                    </View>
                  </View>
                );
              })}
            </View>
          )
        );

      case "Extracurricular":
        return (
          extracurriculars?.totalCount > 0 && (
            <View style={styles.sectionContainer}>
              {yearDescendExtracurricular?.map((extracurricular, index) => {
                const concatenatedDesc = extracurricular.description;
                const removeTrailingBullet = trimTrailingChars(
                  concatenatedDesc?.trim()
                );
                const splitDescArr =
                  removeTrailingBullet?.split("\n").filter((val) => val)
                    .length > 0
                    ? removeTrailingBullet?.split("\n")
                    : [];
                return (
                  <View wrap={false}>
                    {index === 0 && (
                      <Text style={styles.sectionTitle}>Extracurricular</Text>
                    )}
                    <View
                      style={
                        index !== extracurriculars?.totalCount - 1
                          ? styles.experienceContainer
                          : undefined
                      }
                    >
                      <View style={styles.rowAndSpaceBetween}>
                        <View style={styles.flexRowAndWrapAndFixWidthL}>
                          <Text style={styles.experienceTitle}>
                            {extracurricular.title ??
                              "CandidateExtracurricularTitle"}
                          </Text>
                        </View>
                        <Text style={styles.greyAndSmall}>
                          {extracurricular.completionYear ??
                            "CandidateExtracurricularCompletionYear"}
                        </Text>
                      </View>
                      {splitDescArr &&
                        Array.isArray(splitDescArr) &&
                        splitDescArr.length > 0 &&
                        splitDescArr?.map((desc) => (
                          <View style={styles.flexRowAndWrap}>
                            {desc.split(" ").map((word, wordIndex) => (
                              <Text style={styles.experienceDesc}>
                                {word.trim()}{" "}
                              </Text>
                            ))}
                          </View>
                        ))}
                    </View>
                  </View>
                );
              })}
            </View>
          )
        );
    }
  };

  return (
    <Document>
      <Page style={styles.body} size="A4">
        {/* Basic Info */}
        <View style={styles.profileBasicInfo}>
          <View style={styles.flexRow}>
            {!currentUser?.profileImageSquare?.includes(
              "shared/default-profile-picture.png"
            ) && (
              <View>
                <Image
                  style={styles.image}
                  source={`https:${currentUser?.profileImageSquare}`}
                />
              </View>
            )}
            <View style={styles.profileNameContainer}>
              {currentUser?.name && (
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    maxWidth: currentUser?.profileImageSquare?.includes(
                      "default-profile-picture"
                    )
                      ? "360px"
                      : "240px",
                  }}
                >
                  {currentUser?.name.split(" ").map((word) => {
                    return <Text style={styles.title}>{word} </Text>;
                  })}
                </View>
              )}
              {basicInfoJobTitle && (
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    maxWidth: currentUser?.profileImageSquare?.includes(
                      "default-profile-picture"
                    )
                      ? "360px"
                      : "240px",
                  }}
                >
                  {basicInfoJobTitle.split(" ").map((word) => (
                    <Text style={styles.positionTitle}>{word} </Text>
                  ))}
                </View>
              )}
            </View>
          </View>
          <View style={styles.profileBasicInfoContact}>
            <View style={styles.contactDetailsContainer}>
              <Image
                style={styles.contactDetailIcon}
                source={`${contactIcon}`}
              />
              <Text style={styles.contactDetails}>
                {currentUser?.mobileNumber}
              </Text>
            </View>
            <View
              style={
                currentUser?.websiteUrlTitle && currentUser?.websiteUrl
                  ? styles.contactDetailsContainer
                  : styles.lastContactDetailsContainer
              }
            >
              <Image style={styles.contactDetailIcon} source={`${mailIcon}`} />
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Link src={`mailto:${currentUser?.email}`}>
                  {chunkSubstr(currentUser?.email, 21).map((word) => (
                    <Text style={styles.contactDetails}>{word}</Text>
                  ))}
                </Link>
              </View>
            </View>
            {currentUser?.websiteUrlTitle && currentUser?.websiteUrl && (
              <View style={styles.lastContactDetailsContainer}>
                <Image
                  style={styles.contactDetailIcon}
                  source={`${portfolioIcon}`}
                />
                <Link src={currentUser?.websiteUrl}>
                  <Text style={styles.contactDetails}>
                    {currentUser?.websiteUrlTitle}
                  </Text>
                </Link>
              </View>
            )}
          </View>
        </View>

        {/* Summary */}
        {currentUser?.shortSummary?.length > 3 && (
          <Fragment>
            <Text style={styles.sectionTitle}>Summary</Text>
            <View style={styles.sectionContainer}>
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Text style={styles.description}>
                  {currentUser?.shortSummary}{" "}
                </Text>
              </View>
            </View>
          </Fragment>
        )}
        {secSeqFallback.map((section) => handleSectionSequence(section))}
      </Page>
    </Document>
  );
}

export default ResumePdf;
