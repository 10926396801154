// NAVBAR COMPONENT MANAGEMENT
export const UPDATE_NAVBAR_SEARCHBAR_STATUS = "UPDATE_NAVBAR_SEARCHBAR_STATUS";
export const UPDATE_NAVBAR_FILTER_STATUS = "UPDATE_NAVBAR_FILTER_STATUS";
export const UPDATE_SEARCH_BAR_CLICKED_SCROLL_Y =
  "UPDATE_SEARCH_BAR_CLICKED_SCROLL_Y";

// FOR SIGNIN SIGNUP TEXT
export const UPDATE_SIGNIN_TEXT = "UPDATE_SIGNIN_TEXT";
export const UPDATE_SIGNUP_TEXT = "UPDATE_SIGNUP_TEXT";

// FOR SIGNUP ORIGIN
export const UPDATE_SIGNUP_ORIGIN = "UPDATE_SIGNUP_ORIGIN";

// FOR SIGNIN MODAL
export const UPDATE_SHOW_SIGNIN_MODAL_STATUS =
  "UPDATE_SHOW_SIGNIN_MODAL_STATUS";
export const UPDATE_SIGNIN_MODAL_SIGNUP_STATUS =
  "UPDATE_SIGNIN_MODAL_SIGNUP_STATUS";

// MOBILE FILTER COMPONENT MANAGEMENT
export const UPDATE_SHOW_MOBILE_FILTER_STATUS =
  "UPDATE_SHOW_MOBILE_FILTER_STATUS";

// FOR MOBILE SEARCH
export const UPDATE_SHOW_MOBILE_SEARCH = "UPDATE_SHOW_MOBILE_SEARCH";

// FOR NAVBAR SEARCH
export const UPDATE_NAVBAR_SEARCH_KEYWORD = "UPDATE_NAVBAR_SEARCH_KEYWORD";

export const UPDATE_SHOW_STICKY_APPLY_BUTTON =
  "UPDATE_SHOW_STICKY_APPLY_BUTTON";
