import posthog from "posthog-js";

const getJobDetails = (job) => {
  const hideSalary =
    !job?.salary ??
    job?.salary.toString().toLowerCase().includes("undisclosed");
  const [min, max] = job?.salary?.split(" - ").map(Number) ?? [0, 0];
  const scraped = job?.scraped === "true";

  return {
    hideSalary,
    min,
    max,
    scraped,
  };
};

const getJobDiscovery = (jobListTab, viewHistoryState, fypTab) => {
  const jobDiscoveryMap = {
    0: {
      default: null,
      viewHistory: "view_history",
      1: "rec_profile",
      2: "rec_activity",
    },
    1: "search",
  };

  return jobListTab === 0
    ? viewHistoryState
      ? jobDiscoveryMap[0].viewHistory
      : jobDiscoveryMap[0][fypTab] ?? jobDiscoveryMap[0].default
    : jobDiscoveryMap[1];
};

const posthogSignup = (job, company, origin, method) => {
  const { hideSalary, min, max, scraped } = getJobDetails(job);

  posthog.capture("sign_up", {
    origin: origin ?? null,
    method: method ?? null,
    job_id: job?.id ?? null,
    job_title: job?.title ?? null,
    job_type: job?.jobType ?? null,
    category: job?.category ?? null,
    scraped: scraped,
    company_id: company?.id ?? null,
    company_name: company?.name ?? null,
    location: job?.location ?? null,
    state_region: job?.stateRegion ?? null,
    hide_salary: hideSalary,
    career_level: job?.careerLevel ?? null,
    min_salary_range: hideSalary ? min : 0,
    max_salary_range: hideSalary ? max : 0,
  });
};

const posthogJobClick = (job, company, searchTerm, origin, jobDiscovery) => {
  const { hideSalary, min, max, scraped } = getJobDetails(job);

  posthog.capture("job_click", {
    origin: origin,
    job_id: job?.id ?? null,
    job_title: job?.title ?? null,
    job_type: job?.jobType ?? null,
    job_discovery: jobDiscovery,
    category: job?.category ?? null,
    spotlight: job?.spotlight ?? null,
    featured: job?.boosted ?? null,
    keyword: job?.keywordHighlight ?? null,
    scraped: scraped,
    job_slot_type: job?.jobSlotType ?? null,
    track_titles: job?.tracks?.map((track) => track?.title) ?? [],
    search_term: searchTerm ?? "",
    company_id: company?.id ?? null,
    company_name: company?.name ?? null,
    location: job?.location ?? null,
    state_region: job?.stateRegion ?? null,
    hide_salary: hideSalary,
    career_level: job?.careerLevel ?? null,
    min_years_experience: job?.minYearsExperience ?? null,
    max_years_experience: job?.maxYearsExperience ?? null,
    min_salary_range: hideSalary ? min : 0,
    max_salary_range: hideSalary ? max : 0,
  });
};

const posthogJobApply = (job, origin, jobDiscovery) => {
  const { hideSalary, min, max, scraped } = getJobDetails(job);

  posthog.capture("job_apply_click", {
    origin: origin,
    job_id: job?.id ?? null,
    job_title: job?.title ?? null,
    job_type: job?.jobType ?? null,
    job_discovery: jobDiscovery,
    category: job?.category ?? null,
    spotlight: job?.spotlight ?? null,
    featured: job?.boosted ?? null,
    keyword: job?.keywordHighlight ?? null,
    scraped: scraped,
    job_slot_type: job?.jobSlotType ?? null,
    track_titles: job?.tracks?.map((track) => track?.title) ?? [],
    company_id: job?.company?.id ?? null,
    company_name: job?.company?.name ?? null,
    location: job?.location ?? null,
    state_region: job?.stateRegion ?? null,
    hide_salary: hideSalary,
    career_level: job?.careerLevel ?? null,
    min_years_experience: job?.minYearsExperience ?? null,
    max_years_experience: job?.maxYearsExperience ?? null,
    min_salary_range: hideSalary ? min : 0,
    max_salary_range: hideSalary ? max : 0,
  });
};

const posthogHideJob = (job, origin, jobDiscovery) => {
  const { hideSalary, min, max, scraped } = getJobDetails(job);

  posthog.capture("job_hide", {
    origin: origin,
    job_id: job?.id ?? null,
    job_title: job?.title ?? null,
    job_discovery: jobDiscovery,
    scraped: scraped,
    category: job?.category ?? null,
    career_level: job?.careerLevel ?? null,
    hide_salary: hideSalary,
    company_id: job?.company?.id ?? null,
    company_name: job?.company?.name ?? null,
    min_salary_range: hideSalary ? min : 0,
    max_salary_range: hideSalary ? max : 0,
  });
};

export {
  getJobDiscovery,
  posthogHideJob,
  posthogJobApply,
  posthogJobClick,
  posthogSignup,
};
