import { HYDRATE, createWrapper } from "next-redux-wrapper";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { reducer as advice } from "../reducers/advice_reducer";
import { reducer as axios } from "../reducers/axios_reducer";
import { reducer as companies } from "../reducers/company_reducer";
import { reducer as dialog } from "../reducers/dialog_reducer";
import { reducer as jobs } from "../reducers/job_reducer";
import { reducer as navbar } from "../reducers/navbar_reducer";
import { reducer as screen } from "../reducers/screen_reducer";
import { reducer as snackbar } from "../reducers/snackbar_reducer";
import { reducer as user } from "../reducers/user_reducer";

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const combinedReducer = combineReducers({
  user,
  axios,
  jobs,
  companies,
  navbar,
  snackbar,
  dialog,
  advice,
  screen,
});

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // Use previous state
      ...action.payload, // Apply delta from hydration
    };

    // For user
    if (state.user && state.user.user) {
      nextState.user.user = state.user.user;
    }
    // End for user

    // For jobs
    if (state.jobs && state.jobs.jobs) {
      nextState.jobs.jobs = state.jobs.jobs;
    }
    // End for jobs

    //For companies
    if (state.companies && state.companies.companies) {
      nextState.companies.companies = state.companies.companies;
    }
    // End for companies

    // For navbar
    if (state.navbar && state.navbar.showSearchBar) {
      nextState.navbar.showSearchBar = state.navbar.showSearchBar;
    }

    if (state.navbar && state.navbar.showFilter) {
      nextState.navbar.showFilter = state.navbar.showFilter;
    }
    // End for navbar

    // For snackbar
    if (state.snackbar && state.snackbar.showSnackBar) {
      nextState.snackbar.showSnackBar = state.snackbar.showSnackBar;
      nextState.snackbar.snackBarMessage = state.snackbar.snackBarMessage;
    }
    // End for snackbar

    // For dialog
    if (
      state.dialog &&
      state.dialog.dialogTitle &&
      state.dialog.dialogDescription
    ) {
      nextState.dialog.dialogTitle = state.dialog.dialogTitle;
      nextState.dialog.dialogDescription = state.dialog.dialogDescription;
    }
    // End for dialog

    // For advice
    if (state.advice && state.advice.podcasts) {
      nextState.advice.podcasts = state.advice.podcasts;
    }
    // End for advice

    // For screen
    if (state.screen && state.screen.screenWidth) {
      nextState.screen.screenWidth = state.screen.screenWidth;
    }

    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

export const store = createStore(reducer, bindMiddleware([thunkMiddleware]));

const initStore = () => {
  return store;
};

export const wrapper = createWrapper(initStore);
