import { createTheme } from "@mui/material/styles";
import Color from "./colors";
import { MUITypography } from "./muiFonts";

const theme = createTheme({
  palette: {
    primary: {
      main: Color.hiredlyPurple,
    },
    success: {
      main: Color.success,
    },
  },

  typography: { ...MUITypography },

  zIndex: {
    tooltip: 1000,
  },

  breakpoints: {
    values: {
      xs: 0, // default from MUI
      // TODO: resolve TypeScript error
      // @ts-ignore
      mobile: 360,
      mobileL: 480,
      sm: 600, // default from MUI
      tablet: 768,
      tabletS: 780,
      md: 960,
      tabletL: 1200,
      lg: 1280,
      lgXl: 1600,
      desktop: 1440,
      xl: 1920,
      desktopL: 2560,
    },
  },
  styleOverrides: {
    body: {
      scrollbarColor: `${Color.darkGrey} ${Color.hiredlyPurple}`,
      "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        backgroundColor: Color.hiredlyPurple,
      },
      "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        backgroundColor: Color.hiredlyPurple,
        minHeight: 24,
        border: `3px solid ${Color.black}`,
      },
      "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
        backgroundColor: Color.hiredlyPurple,
      },
      "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
        {
          backgroundColor: Color.hiredlyPurple,
        },
      "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
        backgroundColor: Color.hiredlyPurple,
      },
      "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
        backgroundColor: Color.hiredlyPurple,
      },
    },
  },
});

export default theme;
