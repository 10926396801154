import * as types from "../types/dialog_type";

const initialState = {
  //For dialog
  dialogTitle: "",
  dialogDescription: "",
  dialogInfo: {
    visible: false,
    title: "",
    message: "",
    video: "",
    message2: "",
    dialogOrigin: "",
  },
  dialogInfoActivelyAvailable: false,
  dialogConfirm: {
    visible: false,
    title: "",
    message: "",
  },
  showNoCvDialog: false,

  //post apply dialog
  showPostApplyDialog: {
    status: false,
    scraped: false,
    jobGlobalId: "",
  },
  bulkApplyState: "post-apply",

  showJobAlertDialog: false,
  showJobAlertNotificationsDialog: false,
  showJobAlertDeleteDialog: false,
  showJobAlertDownloadAppDialog: false,
  showJobAlertVisitorEmailDialog: false,
  showJobAlertVisitorUnsubscribeDialog: false,
  showJobAlertCreateDialog: false,
  // Visitor Email Modal in Job Details Page
  showJobDetailsVisitorEmailDialog: false,
  showResumeConfirmationDialog: false,
  showProfilePreviewDialog: false,
  showSignUpProfileDialog: false,
  showResumeFeedbackDialog: false,
  visitorEmailDialogInfo: {
    email: "",
    keyword: "",
  },
  logInJobAlertDialogInfo: {
    keyword: "",
  },
  jobAlertKeywordOrigin: "",
  // For secondary visitor dialog
  secondaryVisitorDialog: false,
  // For Ashley short summary dialog
  showAshleyEditNoResumeDialog: false,
  showAshleyEditConfirmationDialog: false,
  showAshleyEditDialog: false,

  showContactUsEmployerDialog: false,
  showInterestCheckDialog: false,

  showViewProductEmployerDialog: false,

  // For SG Job
  showSGJobReminderDialog: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // For dialog
    case types.UPDATE_SG_JOB_REMINDER_DIALOG: {
      return {
        ...state,
        showSGJobReminderDialog: action.payload,
      };
    }
    case types.UPDATE_SIGN_UP_PROFILE_DIALOG: {
      return {
        ...state,
        showSignUpProfileDialog: action.payload,
      };
    }
    case types.UPDATE_PROFILE_PREVIEW_DIALOG: {
      return {
        ...state,
        showProfilePreviewDialog: action.payload,
      };
    }
    case types.UPDATE_RESUME_CONFIRMATION_DIALOG: {
      return {
        ...state,
        showResumeConfirmationDialog: action.payload,
      };
    }

    case types.UPDATE_RESUME_FEEDBACK_DIALOG: {
      return {
        ...state,
        showResumeFeedbackDialog: action.payload,
      };
    }

    case types.SHOW_INFO_DIALOG: {
      return {
        ...state,
        dialogInfo: {
          visible: true,
          title: action.payload?.title,
          message: action.payload?.message,
          onOkClicked: action.payload?.onOkClicked,
          video: action.payload?.video,
          message2: action.payload?.message2,
          dialogOrigin: action.payload?.dialogOrigin,
        },
      };
    }
    case types.SHOW_INFO_DIALOG: {
      return {
        ...state,
        dialogInfo: {
          visible: true,
          title: action.payload?.title,
          message: action.payload?.message,
          onOkClicked: action.payload?.onOkClicked,
          video: action.payload?.video,
          message2: action.payload?.message2,
        },
      };
    }
    case types.SHOW_INFO_DIALOG_ACTIVELY_AVAILABLE: {
      return {
        ...state,
        dialogInfoActivelyAvailable: action.payload,
      };
    }
    case types.CLEAR_INFO_CONTENT: {
      return {
        ...state,
        dialogInfo: {
          visible: false,
          title: "",
          message: "",
          dialogOrigin: "",
        },
      };
    }
    case types.HIDE_INFO_DIALOG: {
      return {
        ...state,
        dialogInfo: {
          ...state.infoDialog,
          visible: false,
        },
      };
    }
    case types.SHOW_CONFIRMATION_DIALOG: {
      return {
        ...state,
        dialogConfirm: {
          visible: true,
          title: action.payload?.title,
          message: action.payload?.message,
          onConfirmClicked: action.payload?.onConfirmClicked,
          onCancelClicked: action.payload?.onCancelClicked,
        },
      };
    }
    case types.CLEAR_CONFIRMATION_CONTENT: {
      return {
        ...state,
        dialogConfirm: {
          visible: false,
          title: "",
          message: "",
        },
      };
    }
    case types.HIDE_CONFIRMATION_DIALOG: {
      return {
        ...state,
        dialogConfirm: {
          ...state.confirmationDialog,
          visible: false,
        },
      };
    }
    case types.UPDATE_NO_CV_DIALOG_STATUS: {
      return {
        ...state,
        showNoCvDialog: action.status,
      };
    }
    //post apply dialog
    case types.UPDATE_POST_APPLY_DIALOG: {
      const { status, scraped, jobGlobalId } = action;

      const cParams = {
        status: status,
        scraped: scraped,
        jobGlobalId: jobGlobalId,
      };

      return {
        ...state,
        showPostApplyDialog: cParams,
      };
    }
    case types.UPDATE_BULK_APPLY_STATE: {
      return {
        ...state,
        bulkApplyState: action.status,
      };
    }
    case types.UPDATE_JOB_ALERT_DIALOG: {
      return {
        ...state,
        showJobAlertDialog: action.status,
      };
    }
    case types.UPDATE_JOB_ALERT_NOTIFICATIONS_DIALOG: {
      return {
        ...state,
        showJobAlertNotificationsDialog: action.status,
      };
    }
    case types.UPDATE_JOB_ALERT_DELETE_DIALOG: {
      return {
        ...state,
        showJobAlertDeleteDialog: action.status,
      };
    }
    case types.UPDATE_JOB_ALERT_DOWNLOAD_APP_DIALOG: {
      return {
        ...state,
        showJobAlertDownloadAppDialog: action.status,
      };
    }
    case types.UPDATE_JOB_ALERT_VISITOR_EMAIL_DIALOG: {
      return {
        ...state,
        showJobAlertVisitorEmailDialog: action.status,
      };
    }
    case types.UPDATE_JOB_ALERT_VISITOR_UNSUBSCRIBE_DIALOG: {
      return {
        ...state,
        showJobAlertVisitorUnsubscribeDialog: action.status,
      };
    }

    case types.UPDATE_JOB_ALERT_CREATE_DIALOG: {
      return {
        ...state,
        showJobAlertCreateDialog: action.status,
      };
    }

    case types.UPDATE_JOB_ALERT_CREATE_DIALOG_KEYWORD: {
      return {
        ...state,
        logInJobAlertDialogInfo: {
          keyword: action.keyword ?? "",
        },
        jobAlertKeywordOrigin:
          action.jobAlertKeywordOrigin ?? state.jobAlertKeywordOrigin,
      };
    }

    case types.UPDATE_JOB_DETAILS_VISITOR_JOB_ALERT_DIALOG: {
      return {
        ...state,
        showJobDetailsVisitorEmailDialog: action.status,
      };
    }

    case types.UPDATE_VISITOR_JOB_ALERT_DIALOG_DETAILS: {
      return {
        ...state,
        visitorEmailDialogInfo: {
          email: action.payload.email ?? state.visitorEmailDialogInfo.email,
          keyword:
            action.payload.keyword ?? state.visitorEmailDialogInfo.keyword,
        },
      };
    }

    case types.UPDATE_VISITOR_JOB_ALERT_DIALOG_ORIGIN: {
      return {
        ...state,
        jobAlertKeywordOrigin: action.origin,
      };
    }

    case types.UPDATE_SECONDARY_VISITOR_DIALOG: {
      return {
        ...state,
        secondaryVisitorDialog: action.payload,
      };
    }

    case types.UPDATE_ASHLEY_EDIT_NO_RESUME_DIALOG: {
      return {
        ...state,
        showAshleyEditNoResumeDialog: action.payload,
      };
    }

    case types.UPDATE_ASHLEY_EDIT_CONFIRMATION_DIALOG: {
      return {
        ...state,
        showAshleyEditConfirmationDialog: action.payload,
      };
    }

    case types.UPDATE_ASHLEY_EDIT_DIALOG: {
      return {
        ...state,
        showAshleyEditDialog: action.payload,
      };
    }

    case types.UPDATE_CONTACT_US_EMPLOYER_DIALOG: {
      return {
        ...state,
        showContactUsEmployerDialog: action.payload,
      };
    }

    case types.UPDATE_VIEW_PRODUCT_EMPLOYER_DIALOG: {
      return {
        ...state,
        showViewProductEmployerDialog: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
